import { decodeException } from '@baxter/web-utils'
import axios from 'axios'
import webClientAuth from '@baxter/web-client-auth'
import CountryList from '../../../../public/config/locales/countryList.json'

export default {
  namespaced: true,
  data() {
    return {
      countries: CountryList.countries,
      benchmarkCountries: [
        {
          id: 'global',
          value: 'Global',
        },
      ].concat(CountryList.countries.sort((a, b) => a.value.localeCompare(b.value))),
      user_role: [
        {
          id: 'global',
          value: 'Global Admin',
        },
        {
          id: 'country',
          value: 'Country Admin',
        },
        {
          id: 'salesrep',
          value: 'Sales Rep',
        },
      ],
      userInfo: 'country',
      countryCode: 'US',
      namespace: 'trueVue',
    }
  },

  methods: {
    async getUsers(request) {
      return this.$store.dispatch(`${this.namespace}/getUsersList`, request)
    },

    async getAssignedUser(request) {
      return this.$store.dispatch(`${this.namespace}/getAssignedUserList`, request)
    },

    async getAssignedCustomer(request) {
      return this.$store.dispatch(`${this.namespace}/getAssignedCustomerList`, request)
    },

    async getCustomers(request) {
      return this.$store.dispatch(`${this.namespace}/getCustomersList`, request)
    },
    async getBenchmarks(request) {
      return this.$store.dispatch(`${this.namespace}/getBenchmarksList`, request)
    },

    async getAdmins(request) {
      return this.$store.dispatch(`${this.namespace}/getAdminsList`, request)
    },

    async addUserForAssignment(request) {
      return this.$store.dispatch(`${this.namespace}/addUserForAssignment`, request)
    },

    async deleteUserForAssignment(request) {
      return this.$store.dispatch(`${this.namespace}/deleteUserForAssignment`, request)
    },
    async addCustomerForAssignment(request) {
      return this.$store.dispatch(`${this.namespace}/addCustomerForAssignment`, request)
    },

    async deleteCustomerForAssignment(request) {
      return this.$store.dispatch(`${this.namespace}/deleteCustomerForAssignment`, request)
    },

    async updateCustomersToUser(request) {
      return this.$store.dispatch(`${this.namespace}/updateCustomersToUser`, request)
    },

    async updateUserImpersonation(request) {
      return this.$store.dispatch(`${this.namespace}/updateUserImpersonation`, request)
    },
    async getUserProfile(request) {
      return this.$store.dispatch(`${this.namespace}/getUserProfile`, request)
    },

    async createBenchmarkRequest(request) {
      return this.$store.dispatch(`${this.namespace}/createBenchmarkRequest`, request)
    },
    async createNewBenchmark(request) {
      return this.$store.dispatch(`${this.namespace}/createNewBenchmark`, request)
    },
    async editBenchmark(request) {
      return this.$store.dispatch(`${this.namespace}/editBenchmark`, request)
    },

    async updateCustomerProfile(request) {
      return this.$store.dispatch(`${this.namespace}/updateCustomerProfile`, request)
    },
    async updateCustomerProfileForAssociation(request) {
      return this.$store.dispatch(`${this.namespace}/updateCustomerProfileForAssociation`, request)
    },
    async updateCustomerProfileForBenchmarkAssociation(request) {
      return this.$store.dispatch(
        `${this.namespace}/updateCustomerProfileForBenchmarkAssociation`,
        request
      )
    },

    async deleteBenchmarkAPI(request) {
      return this.$store.dispatch(`${this.namespace}/deleteBenchmarkAPI`, request)
    },
    async editBenchmarkAPI(request) {
      return this.$store.dispatch(`${this.namespace}/editBenchmarkAPI`, request)
    },

    async loadSchema() {
      try {
        await this.$store.dispatch(`${this.namespace}/loadSchema`)
      } catch (exception) {
        const exceptionDetail = decodeException(exception)

        this.$appStoreHelper.publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
  },
}

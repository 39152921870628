const navigations = [
  {
    api: 'app/addNavigationToDrawer',
    value: {
      groupId: 1,
      item: {
        display: 'apps.trueVue_analytics.nav_link.title',
        routeLink: '/trueVue_analytics/users',
        avatar: '/img/Baxter_TruVue_Icon_100.svg',
        position: 0,
        avatarStyle: { width: 60, height: 60 },
      },
    },
  },
  {
    api: 'app/addLinkToHomePage',
    value: {
      position: 0,
      display: 'apps.trueVue_analytics.home_link.title',
      routeLink: '/trueVue_analytics/users',
      avatar: '/img/Baxter_TruVue_Icon_100.svg',
      description: 'apps.trueVue_analytics.home_link.description',
      link: 'apps.trueVue_analytics.home_link.link',
      avatarStyle: { width: 140, height: 140 },
    },
  },
]

export default navigations

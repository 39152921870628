<template>
  <div>
    <WebDialog ref="baseDialog" :disable-confirm="!disableConfirm" width="770px" height="488px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.customers.dialog_create_benchmark.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto mt-3">
          <v-col cols="12" sm="12" md="6" lg="6" class="header pl-0">
            <div class="name">
              <h4>
                {{ $t('apps.trueVue_analytics.customers.dialog_create_benchmark.benchmark_name') }}
              </h4>
              <div class="errorMessage">
                <span v-if="nameExists">
                  {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.benchmark_name_error') }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" class="header pr-0">
            <h4>
              {{ $t('apps.trueVue_analytics.customers.dialog_create_benchmark.benchmark_abbrv') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0 pb-0">
            <v-text-field
              id="newBenchmarkNameId"
              solo
              data-test="newBenchmarkNameIdTest"
              class="newBenchmarkNameClass"
              type="input"
              :value="newBenchmarkName"
              :label="$t('apps.trueVue_analytics.customers.dialog_create_benchmark.benchmark_name')"
              flat
              :rules="nameRules"
              :maxlength="32"
              :counter="32"
              required
              single-line
              @change="(v) => (newBenchmarkName = v)"
              @input="(v) => benchmarkNameExistence(v)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0 pb-0">
            <v-text-field
              solo
              data-test-id="newBenchmarkAbbrvInput"
              :value="newBenchmarkAbbrv"
              :label="
                $t(
                  'apps.trueVue_analytics.customers.dialog_create_benchmark.benchmark_abbreviation_input'
                )
              "
              flat
              :rules="abbrvRules"
              :counter="3"
              :maxlength="3"
              single-line
              required
              @change="(v) => (newBenchmarkAbbrv = v)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="header pl-0">
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.country') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0" :class="{ 'pb-0': !globalAdmin }">
            <v-select
              v-if="globalAdmin"
              v-model="benchmarkCountry"
              class="selectCountry"
              data-test-id="selectCountry"
              :items="benchmarkCountrySelect.country"
              solo
              :label="
                $t('apps.trueVue_analytics.customers.advanced_filters.combobox_label.country')
              "
              flat
              item-text="display"
              item-value="value"
              hide-details
            ></v-select>
            <v-text-field
              v-if="!globalAdmin"
              :value="benchmarkCountry"
              :label="benchmarkCountrySelect.country.display"
              data-test-id="selectCountry"
              required
              single-line
              readonly
              flat
              solo
              item-text="benchmarkCountrySelect.country.display"
              item-value="benchmarkCountrySelect.country.value"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="accountsHeader pl-0"
            :class="{ 'pt-0': !globalAdmin }"
          >
            <h4>
              {{ $t('apps.trueVue_analytics.customers.dialog_create_benchmark.accounts') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="12" lg="12" class="px-0">
            <v-card class="accountsList mx-auto" elevation="0" outlined>
              <v-list>
                <v-list-item-group multiple>
                  <template v-for="item in selectedAccounts">
                    <v-list-item
                      :key="item"
                      :value="item"
                      active-class="deep-blue--text text--accent-4"
                    >
                      <template v-slot="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            class="remove text-none"
                            :input-value="active"
                            :true-value="item"
                            rounded
                            elevation="0"
                            @click="removeCustomerFromList(item)"
                          >
                            Remove
                            <v-icon right>close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:confirm-button-text data-test-id="create-benchmark-button">
        <span>
          {{ $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.button_save') }}
        </span>
      </template>
    </WebDialog>

    <WebConfirmDialog ref="confirm">
      <template v-slot:dialog-icon>
        <v-icon color="#BE531C" x-large>info</v-icon>
      </template>

      <template v-slot:dialog-title>
        <span>
          {{ $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.confirm_title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <span>
          {{ $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.confirm_message') }}
        </span>
      </template>
    </WebConfirmDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'CreateBenchmark',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    customerDetails: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    userId: localStorage.getItem('user_id'),
    newBenchmarkName: null,
    newBenchmarkAbbrv: null,
    selectedAccounts: [],
    benchmarkCountry: null,
    benchmarkCountrySelect: {
      country: [],
    },
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    adminCountry: localStorage.getItem('country'),
    user_id: localStorage.getItem('user_id'),
    nameRules: [
      (v) => !!v || 'Benchmark name is required',
      (v) =>
        (v !== null && v.length > 2 && v.length < 33) ||
        'Benchmark name must be between 3 and 32 characters',
      (v) => !/([/])/.test(v) || 'Benchmark name must not include: /',
    ],
    abbrvRules: [
      (v) => !!v || 'Benchmark abbreviation is required',
      (v) =>
        (v !== null && v.length > 0 && v.length < 4) ||
        'Benchmark abbreviation must be between 1 and 3 characters',
    ],
    nameExists: false,
    listOfBenchmarks: [],
  }),
  computed: {
    disableConfirm() {
      if (this.validateData() && !this.nameExists) {
        return true
      }
      return false
    },
  },
  methods: {
    async open() {
      this.$refs.loader.show()
      this.clearBenchmarkData()
      await this.loadBenchmarkData()
      this.$refs.loader.hide()
      const result = await this.$refs.baseDialog.show()
      if (result === true) {
        this.selectedAccounts.map((item) => {
          const key = Object.keys(item)
          key.map((item2) => {
            if (item2 === 'display') {
              item.customername = item.display
              delete item.display
            }

            if (item2 === 'value') {
              item.customerkey = item.value
              delete item.value
            }
          })
        })

        const benchmarkData = {
          benchmark_name: this.newBenchmarkName.trim(),
          legend_abbreviation: this.newBenchmarkAbbrv,
          customers: this.selectedAccounts,
          benchmark_country_code: this.globalAdmin ? this.benchmarkCountry : this.adminCountry,
        }

        if (this.validateData()) {
          return new Promise((resolve, reject) => {
            resolve(benchmarkData)
          })
        }
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
      this.$refs.loader.hide()
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
    benchmarkNameExistence(value) {
      let filteredBenchmarkArray = []
      if (value) {
        filteredBenchmarkArray = this.listOfBenchmarks.filter(
          (benchmark) =>
            benchmark.benchmark_name.includes(value) && benchmark.benchmark_name == value
        )

        if (filteredBenchmarkArray.length > 0) {
          this.nameExists = true
          return true
        }
        this.nameExists = false
        return false
      }
      this.nameExists = false
      return false
    },
    validateData() {
      if (
        !this.globalAdmin &&
        this.newBenchmarkName !== null &&
        this.newBenchmarkName !== '' &&
        this.newBenchmarkAbbrv !== null &&
        this.newBenchmarkAbbrv !== '' &&
        !/([/])/.test(this.newBenchmarkName)
      ) {
        return true
      }
      if (
        this.globalAdmin &&
        this.newBenchmarkName !== null &&
        this.newBenchmarkName !== '' &&
        this.newBenchmarkAbbrv !== null &&
        this.newBenchmarkAbbrv !== '' &&
        !/([/])/.test(this.newBenchmarkName) &&
        this.benchmarkCountry !== null
      ) {
        return true
      }
      return false
    },
    async loadBenchmarkData() {
      try {
        const benchRequest = {
          params: {
            _limit: 3000,
            _page: 1,
            _orderBy: 'benchmark_name',
            _ascending: false,
          },
          headers: {},
        }

        const tempDataFilters = {
          customers: JSON.stringify([]),
          country: JSON.stringify([]),
        }

        benchRequest.params = Object.assign(benchRequest.params, tempDataFilters)

        const benchmarksResponse = await this.getBenchmarks(benchRequest)
        this.listOfBenchmarks = benchmarksResponse.data
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    clearBenchmarkData() {
      this.nameExists = false
      if (this.$refs.benchmarkNameInput) {
        this.$refs.benchmarkNameInput.resetValidation()
      }
      if (this.$refs.benchmarkAbbrvInput) {
        this.$refs.benchmarkAbbrvInput.resetValidation()
      }
      this.newBenchmarkName = null
      this.newBenchmarkAbbrv = null
      this.benchmarkCountry = null
      this.selectedAccounts = this.customerDetails
      const countryList = this.benchmarkCountries

      if (!this.globalAdmin) {
        const countryAdminCountry = countryList.filter(
          (country) => country.id === this.adminCountry
        )

        this.benchmarkCountrySelect.country.display = countryAdminCountry[0].value
        this.benchmarkCountrySelect.country.value = countryAdminCountry[0].id
      } else {
        countryList.forEach((item) => {
          this.benchmarkCountrySelect.country.push({
            display: item.value,
            value: item.id,
          })
        })
      }
    },
    removeCustomerFromList(selectedItem) {
      this.selectedAccounts.splice(selectedItem, 1)
    },
    restrictChars($event) {
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true
      }
      $event.preventDefault()
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    .v-text-field__slot {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}

span {
  &.title {
    color: #54585a;
    font-family: 'Noto Sans Bold', sans-serif !important;
    font-size: 18px !important;
    font-weight: 700;
    line-height: 24px;
  }
  &.dialogTitle {
    vertical-align: -webkit-baseline-middle;
  }
}

::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

v.divider {
  border: none;
}

::v-deep .v-dialog.v-dialog--active.v-dialog--persistent {
  width: 770px;
  height: 488px;
}
h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}
.textAlignCenter {
  text-align: center;
}
.accountsList {
  width: 729px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
}

.header {
  padding: 0 12px;
}

.accountsHeader {
  padding: 12px 12px 0;
}

button.remove {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 87px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

::v-deep .v-list-item {
  .v-list-item__content {
    .v-list-item__title {
      font-size: 14px;
      line-height: 19px;
      font-family: 'Noto Sans', sans-serif;
      color: #54585a;
    }
  }
}

.selectCountry {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.name {
  display: flex;
}

.errorMessage {
  color: #ff5252;
  font-family: 'Noto Sans';
  font-size: 12px;
  line-height: 19px;
  padding-left: 6px;
  margin-bottom: -1px;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}
.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: 110px;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}
</style>,

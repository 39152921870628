<template>
  <div>
    <WebDialog ref="baseDialog" width="600px" max-width="600px">
      <template v-slot:dialog-title>
        <span v-if="!userImpersonatedDetails" class="text-h6">
          {{ $t('apps.trueVue_analytics.users.impersonate_user.title') }}
        </span>
        <span v-if="userImpersonatedDetails" class="text-h6">
          {{ $t('apps.trueVue_analytics.users.impersonate_user.stop_impersonation.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <span v-if="!userImpersonatedDetails" class="impersonation">
          {{ $t('apps.trueVue_analytics.users.impersonate_user.impersonate_user_message_one') }}
          {{ userDetails.first_name + ' ' + userDetails.last_name }}
          {{ $t('apps.trueVue_analytics.users.impersonate_user.impersonate_user_message_second') }}
        </span>
        <span v-if="userImpersonatedDetails" class="impersonation">
          {{
            $t(
              'apps.trueVue_analytics.users.impersonate_user.stop_impersonation.stop_impersonation_message'
            )
          }}
          {{ userImpersonatedDetails }}?
        </span>
      </template>

      <template v-slot:confirm-button-text>
        <span v-if="!userImpersonatedDetails">
          {{ $t('apps.trueVue_analytics.users.impersonate_user.button_save') }}
        </span>
        <span v-if="userImpersonatedDetails">
          {{ $t('apps.trueVue_analytics.users.impersonate_user.stop_impersonation.button_save') }}
        </span>
      </template>
    </WebDialog>

    <WebConfirmDialog ref="confirm">
      <template v-slot:dialog-icon>
        <v-icon color="#BE531C" x-large>info</v-icon>
      </template>
    </WebConfirmDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import { trueVueMgmtStoreHelper } from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import { EventBus } from '@src/event-bus.js'

export default {
  name: 'ImpersonateUser',
  props: {
    userDetails: {
      type: Object,
      required: false,
      default() {
        return {
          first_name: '',
          last_name: '',
        }
      },
    },
    userImpersonatedDetails: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    user: {
      impersonateUserEmail: [],
    },
  }),
  methods: {
    async open() {
      if (this.userDetails) {
        this.user.impersonateUserEmail.push({
          display: `${this.userDetails.first_name} ${this.userDetails.last_name}`,
          value: this.userDetails.user_id,
        })
        const result = await this.$refs.baseDialog.show()
        if (result === true) {
          if (this.user.impersonateUserEmail.length > 0) {
            return new Promise((resolve, reject) => {
              resolve(this.user.impersonateUserEmail)
            })
          }
          return new Promise((resolve, reject) => {
            resolve(false)
          })
        }
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  .v-input__control {
    .v-input__slot {
      margin-bottom: 0px !important;
    }
  }
}
.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 600px;
}
h4 {
  float: right;
}
span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}
.impersonation {
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
}

::v-deep .container {
  padding: 12px 0 !important;
}
</style>,

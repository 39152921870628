<template>
  <div>
    <BenchmarkAdvancedFilters
      :selected-data-filters.sync="dataFilters"
      :new-benchmark-created.sync="benchmarkCreated"
    />

    <WebDataGrid
      class="no_alternate_row_color no_special_row_hover"
      :grid-config="gridConfig"
      :selected-rows.sync="selectedRows"
      @refresh-grid-event="refreshGrid"
    >
      <template v-slot:actions-header>
        <v-list single-line>
          <v-list-item :disabled="disableMenuOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="edit-benchmark-button" @click="editBenchmark">
                {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.edit_benchmark_button') }}
              </WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list single-line>
          <v-list-item :disabled="disableMenuOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="delete-benchmark-button" @click="deleteBenchmark">
                {{
                  $t('apps.trueVue_analytics.benchmark.delete_benchmark.delete_benchmark_button')
                }}
              </WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:actions-footer></template>

      <template v-slot:item.contributing_customers="{ item }">
        <AssociatedCustomers :items="item.contributing_customers" />
      </template>
      <template v-slot:item.created_ts="{ item }">
        {{ item.created_ts ? formatDate(item.created_ts) : '-' }}
      </template>
      <template v-slot:item.updated_ts="{ item }">
        {{ item.updated_ts ? formatDateWithTime(item.updated_ts) : '-' }}
      </template>
    </WebDataGrid>

    <WebConfirmDialog ref="confirm">
      <template v-slot:dialog-icon>
        <v-icon color="#BE531C" x-large>info</v-icon>
      </template>
    </WebConfirmDialog>

    <WebDataLoading ref="loader"></WebDataLoading>

    <EditBenchmark
      ref="editBenchmark"
      :benchmark-details="selectedRows[0]"
      :is-edit-dialog="isEditDialog"
    ></EditBenchmark>
    <DeleteBenchmark ref="deleteBenchmark" :benchmark-details="selectedRows[0]"></DeleteBenchmark>
    <DeleteBenchmarkConfirmation
      ref="deleteBenchmarkConfirmation"
      :benchmark-details="selectedRows[0]"
    ></DeleteBenchmarkConfirmation>
  </div>
</template>,

<script>
import { formatDateTimeRelative, decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import moment from 'moment'
import { filter } from 'minimatch'

const BenchmarkAdvancedFilters = () =>
  import('@plugins/trueVue-analytics/components/advanced-filters/benchmark')
const EditBenchmark = () => import('@plugins/trueVue-analytics/components/editBenchmark')
const DeleteBenchmark = () => import('@plugins/trueVue-analytics/components/deleteBenchmark')
const DeleteBenchmarkConfirmation = () =>
  import('@plugins/trueVue-analytics/components/deleteBenchmarkConfirmation')
const AssociatedCustomers = () =>
  import('@plugins/trueVue-analytics/components/association-list/customers')

export default {
  name: 'Benchmark',
  components: {
    BenchmarkAdvancedFilters,
    EditBenchmark,
    DeleteBenchmark,
    DeleteBenchmarkConfirmation,
    AssociatedCustomers,
  },
  mixins: [trueVueMgmtStoreHelper],
  data: () => ({
    dataFilters: {},
    benchmarkCreated: {},
    selectedRows: [],
    user_id: localStorage.getItem('user_id'),
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    isEditDialog: true,
    gridConfig: {
      records: [],
      loading: true,
      itemKey: 'benchmark_name',
      highlightKey: 'benchmark_name',
      tableHeight: '100%',
      options: {
        itemsPerPageOptions: [10, 25, 50, 100],
        totalPages: 0,
        itemsPerPage: 10,
        page: 1,
        sortBy: 'benchmark_name',
        descending: false,
      },
      headers: [
        {
          text: '__HEADER__MENU__',
          value: `__HEADER__MENU__`,
          sortable: false,
          fixed: true,
          width: '1px',
        },
        {
          text: 'Benchmark Name',
          value: 'benchmark_name',
          sortable: true,
          fixed: true,
          class: 'benchmark',
        },
        {
          text: 'Input Accounts',
          value: 'contributing_customers',
          sortable: false,
          fixed: true,
          class: 'inputAccounts',
        },
        {
          text: 'Country of Creation',
          value: 'country_of_creation',
          sortable: false,
          fixed: true,
          class: 'country',
        },
        {
          text: '# of Assigned Accounts',
          value: 'assigned_customers_length',
          sortable: false,
          fixed: true,
          class: 'numberOfAccounts',
        },
        {
          text: 'Status',
          value: 'benchmark_status',
          sortable: false,
          fixed: true,
          class: 'status',
        },
        {
          text: 'Date Created',
          value: 'created_ts',
          sortable: true,
          fixed: true,
          class: 'date',
        },
        {
          text: 'Last Modified',
          value: 'updated_ts',
          sortable: true,
          fixed: true,
          class: 'lastModified',
        },
      ],
    },
    // globalAdmin: localStorage.getItem('globaladmin'),
    // countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
  }),
  computed: {
    disableMenuOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length !== 1 ||
          !this.selectedRows.some((item) => item.country_of_creation === this.currentCountryCode) ||
          !this.selectedRows.some((item) => {
            const filterArray = item.contributing_customers.filter(
              (customer) => customer.country !== item.country_of_creation
            )
            return !(filterArray.length > 0)
          })
        )
      }
      return this.selectedRows.length !== 1
    },
  },
  watch: {
    async dataFilters(filters) {
      this.dataFilters = filters
      this.gridConfig.options.page = 1
      await this.refreshGrid()
    },
    async benchmarkCreated(benchmarkResponse) {
      await this.refreshGrid()
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).format('ll')
    },
    formatDateWithTime(value) {
      return moment(value).format('ll | LT')
    },
    async editBenchmark() {
      try {
        const result = await this.$refs.editBenchmark.open()

        if (result !== false) {
          this.$refs.loader.show()
          const benchmarkName = result.benchmark_name
          const benchmarkAbbrev = result.legend_abbreviation
          const customersArray = []
          let benchmarkCountry = result.benchmark_country_code
          const benchmarkType = result.benchmark_type

          if (this.globalAdmin) {
            benchmarkCountry = result.benchmark_country_code
          }

          result.customers.map((item) => {
            customersArray.push(item.value)
          })

          const request = {
            data: {
              benchmark_name: benchmarkName,
              legend_abbreviation: benchmarkAbbrev,
              customers: customersArray,
              benchmark_type: benchmarkType,
              benchmark_country_code: benchmarkCountry,
            },
            params: {
              benchmark_name: this.selectedRows[0].benchmark_name,
            },
          }
          const response = await this.editBenchmarkAPI(request)
          this.$refs.loader.hide()
          this.$publishAlert(
            this.selectedRows[0].benchmark_name +
              this.$t('apps.trueVue_analytics.benchmark.messages.benchmark_edited_success'),
            'success'
          )
          await this.refreshGrid()
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async deleteBenchmark() {
      try {
        const result = await this.$refs.deleteBenchmark.open()
        if (result) {
          this.deleteBenchmarkConfirmation()
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async deleteBenchmarkConfirmation() {
      try {
        const result = await this.$refs.deleteBenchmarkConfirmation.open()
        if (result) {
          this.$refs.loader.show()
          const request = {
            benchmarkName: this.selectedRows[0].benchmark_name,
          }

          const response = await this.deleteBenchmarkAPI(request)

          if (response) {
            this.$refs.loader.hide()
            this.$publishAlert(
              this.selectedRows[0].benchmark_name +
                this.$t('apps.trueVue_analytics.benchmark.messages.benchmark_deleted_success'),
              'success'
            )
            await this.refreshGrid()
          }
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async refreshGrid(
      pageSize = this.gridConfig.options.itemsPerPage,
      pageNumber = this.gridConfig.options.page,
      sortBy = this.gridConfig.itemKey
    ) {
      try {
        let orderBy = this.gridConfig.itemKey
        let descending = true

        if (this.gridConfig.options.sortBy.length > 0) {
          orderBy = this.gridConfig.options.sortBy[0]
        }

        if (this.gridConfig.options.sortBy.length > 0) {
          descending = this.gridConfig.options.sortDesc[0]
        }

        const request = {
          params: {
            _limit: pageSize,
            _page: pageNumber,
            _orderBy: orderBy,
            _ascending: !descending,
          },
          headers: {},
        }
        const tempDataFilters = {
          customers: JSON.stringify([]),
          country: JSON.stringify([]),
        }

        if (
          !(
            Object.entries(this.dataFilters).length === 0 && this.dataFilters.constructor === Object
          )
        ) {
          Object.keys(this.dataFilters).forEach((key, index) => {
            tempDataFilters[key] =
              typeof this.dataFilters[key] === 'string'
                ? this.dataFilters[key]
                : JSON.stringify(this.dataFilters[key])
          })
        }

        request.params = Object.assign(request.params, tempDataFilters)
        this.gridConfig.loading = true
        const response = await this.getBenchmarks(request)
        this.gridConfig.options.totalPages = response.total_pages
        this.gridConfig.records = response.data
        this.gridConfig.options.totalItems = response.total
        this.gridConfig.options.page = response._page
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.gridConfig.loading = false
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep i.v-icon {
  color: #001a72 !important;
}

::v-deep th {
  white-space: nowrap;
}

::v-deep th.text-start {
  padding: 3px 5px;
  background-color: rgba(84, 88, 90, 0.1) !important;
  span {
    color: #001a72;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-align: right;
    &:hover {
      color: #001a72;
    }
  }
}

::v-deep td.text-start {
  padding: 3px 5px;
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  height: 54px;
  max-height: 54px;
  &:nth-child(1) {
    padding-left: 20px;
  }
  &:nth-child(2) {
    padding-left: 20px;
  }
  &:nth-child(4) {
    max-width: 200px;
  }
  //  max-width: 150px;
}

::v-deep th.benchmark {
  min-width: 130px;
}

::v-deep th.inputAccounts {
  max-width: 150px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}

::v-deep th.country {
  min-width: 139px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}

::v-deep th.numberOfAccounts {
  min-width: 160px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}

::v-deep th.status {
  min-width: 64px;
}

::v-deep th.date {
  min-width: 103px;
}

::v-deep th.lastModified {
  min-width: 105px;
}

::v-deep .v-data-table__wrapper {
  border-top: none !important;
}

::v-deep .v-simple-checkbox {
  i.v-icon {
    height: 12px;
    width: 12px;
    color: gray !important;
  }
}

.rowSize {
  max-height: 47px;
  overflow-y: auto;
}

.chips {
  margin: 1px 2px 1px 0;
  border-radius: 30px;
  background-color: #edeeee;
  span {
    color: #54585a;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
}

.v-list-item {
  padding: 0 30px;
  .v-list-item__title {
    color: #4066b3;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
}
.v-chip.v-size--default {
  height: 22px;
  font-size: 12px;
}

.v-list {
  padding: 0px;
  &:first-child {
    padding-top: 15px;
  }
  &:last-child {
    padding-bottom: 15px;
  }
}

::v-deep .v-data-table-header .v-simple-checkbox {
  display: none;
}
</style>,

<style type="text/css">
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menuable__content__active {
    width: 185px;
  }
}
</style>,

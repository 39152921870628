<template>
  <div id="assignCustomerToUser" class="assignment">
    <WebDialog ref="baseDialog" width="900px" height="480px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0 accountHeaders">
            <h4>
              {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.all_customers') }}
            </h4>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0 accountHeaders">
            <h4>
              {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.assigned_customers') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0">
            <v-text-field
              v-model="searchQuery"
              solo
              class="elevation-0 search"
              data-test-id="search-available-customers"
              :label="
                $t('apps.trueVue_analytics.users.assign_customer_to_user.search_available_accounts')
              "
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="searchForCustomersCheck"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              :value="assignedCustomersSearch"
              solo
              class="elevation-0 search"
              data-test-id="search-assigned-customers"
              :label="
                $t('apps.trueVue_analytics.users.assign_customer_to_user.search_assigned_accounts')
              "
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="(v) => (assignedCustomersSearch = v)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card class="customerLists mx-auto" elevation="0" outlined>
            <v-list>
              <v-list-item-group multiple>
                <template v-for="(item, i) in filteredItems" v-show="!item.is_customer_assigned">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="!item.is_customer_assigned"
                    :key="`item-${i}`"
                    data-test-id="available-customers"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <div class="account_content_layout">
                          <v-icon ref="info" left class="account_info_trigger">info</v-icon>
                          <div ref="popper" class="account-popper" v-text="item.info" />
                          <v-list-item-title v-text="item.display"></v-list-item-title>
                        </div>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== currentCountryCode"
                          class="add text-none"
                          data-test-id="add-button"
                          :input-value="active"
                          :true-value="item"
                          rounded
                          elevation="0"
                          @click="addCustomerToAssignedList(item)"
                        >
                          Add
                          <v-icon right>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>

                <v-list-item v-if="filteredItems.length === 0" class="justify-center">
                  <label v-if="!customersListLoading" class="noAssignedData"
                    >No records found</label
                  >
                  <v-progress-circular
                    v-if="customersListLoading"
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-list-item>

                <v-list-item v-show="isCustomersLoadMoreAvailable">
                  <v-btn
                    data-test-id="load-more-button"
                    block
                    text
                    color="primary"
                    :loading="customersListLoading"
                    @click="loadMoreCustomers"
                  >
                    {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.load_more') }}
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card class="customerLists mx-auto" elevation="0" outlined>
            <div v-if="changesBeingMade" class="notSaved">
              <span>
                {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.not_yet_saved') }}
              </span>
            </div>

            <v-list>
              <v-list-item-group multiple>
                <template
                  v-for="(item, i) in selectedFilteredItems"
                  v-show="item.is_customer_assigned"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                  <v-list-item
                    v-else-if="item.is_customer_assigned"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active, toggle }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== currentCountryCode"
                          class="remove text-none"
                          :input-value="active"
                          :true-value="item"
                          data-test-id="remove-button"
                          rounded
                          elevation="0"
                          @click="removeCustomerFromAssignedList(item)"
                        >
                          Remove
                          <v-icon right>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="selectedFilteredItems.length === 0" class="justify-center">
                  <label v-if="!inputAccountSearch" class="noAssignedData"
                    >{{
                      $t(
                        'apps.trueVue_analytics.users.assign_customer_to_user.no_assigned_accounts'
                      )
                    }}
                  </label>
                  <label v-if="inputAccountSearch" class="noAssignedData">
                    {{
                      $t('apps.trueVue_analytics.users.assign_customer_to_user.no_records_found')
                    }}
                  </label>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span>
          {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.button_save') }}
        </span>
      </template>
    </WebDialog>
    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'AssignCustomerToUser',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    userDetails: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    searchOptions: {
      name: [],
    },
    items: [],
    selected: [],
    search: '',
    availableCustomersSearch: '',
    assignedCustomersSearch: '',
    selectedSearchFilters: { name: [] },
    userId: localStorage.getItem('user_id'),
    changesBeingMade: false,
    limit: 30,
    page: 1,
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
    deletedFilters: [],
    customersPage: 2,
    customersListLoading: false,
    isCustomersLoadMoreAvailable: false,
    searchQuery: '',
    searchTimeout: null,
  }),
  computed: {
    filteredItems() {
      return this._.orderBy(
        this.searchOptions.name.filter((item) => {
          if (!this.availableCustomersSearch) {
            return this.searchOptions.name
          }
          return item.display
            ? item.display.toLowerCase().includes(this.availableCustomersSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
    selectedFilteredItems() {
      return this._.orderBy(
        this.selectedSearchFilters.name.filter((item) => {
          if (!this.assignedCustomersSearch) {
            return this.selectedSearchFilters.name
          }
          return item.display
            ? item.display.toLowerCase().includes(this.assignedCustomersSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
  },
  methods: {
    async open() {
      this.$refs.loader.show()
      await this.loadFilters()
      this.$refs.loader.hide()
      const result = await this.$refs.baseDialog.show()
      if (result === true) {
        if (
          (this.selectedSearchFilters.name.length > 0 || this.deletedFilters.length > 0) &&
          this.changesBeingMade
        ) {
          const customerData = {
            selectedSearchFilters: this.selectedSearchFilters.name,
            deletedFilters: this.deletedFilters,
          }
          return new Promise((resolve, reject) => {
            resolve(customerData)
          })
        }
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
      this.$refs.loader.hide()
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
    async loadFilters() {
      try {
        this.customersPage = 2
        this.searchQuery = ''
        this.selectedSearchFilters.name = []
        this.searchOptions.name = []
        this.deletedFilters = []
        this.changesBeingMade = false

        this.userDetails.customers.forEach((element) => {
          this.selectedSearchFilters.name.push({
            display: element.customername,
            value: element.customerkey,
            is_customer_assigned: true,
            country_code: element.country,
          })
          this.deletedFilters.push({
            display: element.customername,
            value: element.customerkey,
            is_customer_assigned: true,
            country_code: element.country,
          })
        })

        const request = {
          params: {
            user_id: this.userId,
            _limit: this.limit,
            _page: this.page,
            _orderBy: 'name',
          },
          selectedUserId: String(this.userDetails.user_id),
        }
        const response = await this.getAssignedCustomer(request)

        if (response.total_pages > 1) {
          this.isCustomersLoadMoreAvailable = true
        }

        if (response) {
          response.data.forEach((items) => {
            this.searchOptions.name.push({
              display: items.name,
              value: items.customerkey,
              is_customer_assigned: items.is_customer_assigned ? items.is_customer_assigned : false,
              country_code: items.country_code,
              state: items.state,
              info: `${items.name}${items.state ? `, ${items.state}` : ''}${
                items.country_code ? `, ${items.country_code}` : ''
              }`,
            })
          })
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },

    async loadMoreCustomers() {
      try {
        this.customersListLoading = true

        const request = {
          params: {
            user_id: this.userId,
            _limit: this.limit,
            _page: this.customersPage,
            _orderBy: 'name',
            search_term: this.searchQuery,
          },
          selectedUserId: String(this.userDetails.user_id),
        }
        const response = await this.getAssignedCustomer(request)

        if (response) {
          response.data.forEach((items) => {
            const removedFromAssignedArray = this.selectedSearchFilters.name.filter(
              (customer) => customer.value === items.customerkey
            )
            if (removedFromAssignedArray.length > 0) {
              items.is_customer_assigned = true
            } else {
              items.is_customer_assigned = false
            }
            this.searchOptions.name.push({
              display: items.name,
              value: items.customerkey,
              is_customer_assigned: items.is_customer_assigned ? items.is_customer_assigned : false,
              country_code: items.country_code,
              state: items.state,
              info: `${items.name}${items.state ? `, ${items.state}` : ''}${
                items.country_code ? `, ${items.country_code}` : ''
              }`,
            })
          })

          if (this.customersPage === response.total_pages) {
            this.isCustomersLoadMoreAvailable = false
          }

          this.customersPage += 1
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.customersListLoading = false
    },

    async searchForCustomersCheck() {
      const value = this.searchQuery
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchForCustomers(value)
      }, 500)
    },

    async searchForCustomers(value) {
      this.isCustomersLoadMoreAvailable = false
      this.customersListLoading = true
      this.customersPage = 2
      this.searchOptions.name = []
      const request = {
        params: {
          user_id: this.userId,
          _limit: value ? 3000 : this.limit,
          _page: this.page,
          _orderBy: 'name',
          search_term: value,
        },
        selectedUserId: String(this.userDetails.user_id),
      }
      try {
        const response = await this.getAssignedCustomer(request)

        if (value !== this.searchQuery) {
          return
        }

        if (response.total_pages > 1) {
          this.isCustomersLoadMoreAvailable = true
        }

        if (response) {
          response.data.forEach((items) => {
            const removedFromAssignedArray = this.selectedSearchFilters.name.filter(
              (customer) => customer.value === items.customerkey
            )
            if (removedFromAssignedArray.length > 0) {
              items.is_customer_assigned = true
            } else {
              items.is_customer_assigned = false
            }
            this.searchOptions.name.push({
              display: items.name,
              value: items.customerkey,
              is_customer_assigned: items.is_customer_assigned ? items.is_customer_assigned : false,
              country_code: items.country_code,
              state: items.state,
              info: `${items.name}${items.state ? `, ${items.state}` : ''}${
                items.country_code ? `, ${items.country_code}` : ''
              }`,
            })
          })
          this.customersListLoading = false
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
        this.customersListLoading = false
      }
    },

    addCustomerToAssignedList(selectedItem) {
      const currentListOfCustomers = this.selectedSearchFilters.name

      if (!currentListOfCustomers.includes(selectedItem)) {
        this.selectedSearchFilters.name.push({
          display: selectedItem.display,
          value: selectedItem.value,
          is_customer_assigned: true,
          country_code: selectedItem.country_code,
        })

        this.searchOptions.name.map((items) => {
          if (items.value === selectedItem.value) {
            items.is_customer_assigned = true
          }
        })
        this.changesBeingMade = true
      }
    },
    removeCustomerFromAssignedList(selectedItem) {
      const removedCustomer = selectedItem
      this.selectedSearchFilters.name = this.selectedSearchFilters.name.filter(
        (item) => item.value !== selectedItem.value
      )
      this.searchOptions.name.map((items) => {
        if (items.value === selectedItem.value) {
          items.is_customer_assigned = false
        }
      })
      this.changesBeingMade = true
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    width: 250px;
    .v-text-field__slot {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}

::v-deep .v-dialog {
  box-sizing: border-box;
  min-height: 450px;
  width: 970px;
  border: 1px solid #54585a;
  border-radius: 10px !important;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}

::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

.noAssignedData {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
}

.divider {
  border: none;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.customerLists {
  width: 400px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
  .v-list {
    padding-top: 0;
    .v-list-item {
      min-height: 43px;
      padding: 0 9px;
      .v-list-item__content {
        .v-list-item__title {
          color: #54585a;
          font-family: 'Noto Sans';
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

button.add {
  min-height: 0px !important;
  max-height: 21px;
  min-width: 0px !important;
  max-width: 63px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

button.remove {
  min-height: 0px !important;
  max-height: 21px;
  min-width: 0px !important;
  max-width: 87px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

::v-deep .v-btn__content {
  i.v-icon {
    font-weight: bold;
  }
}

.dialogContent {
  justify-content: space-evenly;
}

.notSaved {
  background-color: #eff0f0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 9px;
  line-height: 19px;
  padding-left: 4px;
  color: #54585a;
}

::v-deep .col-md-4.col-lg-5.col {
  text-align-last: end;
  margin-left: 430px;
  button.v-btn {
    span {
      text-transform: none !important;
      color: #ffffff;
      font-family: 'Noto Sans';
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      text-align: center;
    }
  }
}

::v-deep .col-lg-3.col {
  a {
    color: #4066b3;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
}

.accountHeaders {
  padding: 12px 12px 0;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}
.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: 110px;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}

.account_info_trigger {
  font-size: 18px !important;
}

.account_content_layout {
  display: flex;
  align-items: center;
}

.account-popper {
  position: absolute;
  z-index: 1;
  left: 35px;
  background: #ffffff;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px;
  max-width: 250px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: none;
}

.account_content_layout .account_info_trigger:hover ~ .account-popper {
  display: block;
}
</style>,

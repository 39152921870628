import Vue from 'vue'
import Vuetify, {
  VRow,
  VCol,
  VCard,
  VAlert,
  VList,
  VCardTitle,
  VIcon,
  VDialog,
  VContainer,
  VBtn,
  VSnackbar,
  VDataTable,
  VSelect,
  VMenu,
  VPagination,
  VProgressLinear,
  VListItem,
  VSpacer,
  VCardActions,
  VDivider,
  VProgressCircular,
  VCardText,
  VTextField,
  VSimpleCheckbox,
  VTabs,
  VTab,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VAvatar,
  VSubheader,
  VNavigationDrawer,
  VImg,
  VListItemAvatar,
  VAppBar,
  VAppBarNavIcon,
  VToolbarTitle,
  VToolbarItems,
  VApp,
  VChip,
  VFooter,
  VBreadcrumbs,
  VContent,
  VBreadcrumbsItem,
} from 'vuetify/lib'

import { Ripple, Resize } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components: {
    VBtn,
    VRow,
    VCol,
    VCard,
    VAlert,
    VList,
    VCardTitle,
    VIcon,
    VDialog,
    VContainer,
    VSnackbar,
    VDataTable,
    VSelect,
    VMenu,
    VPagination,
    VProgressLinear,
    VListItem,
    VSpacer,
    VCardActions,
    VDivider,
    VProgressCircular,
    VCardText,
    VTextField,
    VSimpleCheckbox,
    VTabs,
    VTab,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VAvatar,
    VSubheader,
    VNavigationDrawer,
    VImg,
    VListItemAvatar,
    VAppBar,
    VAppBarNavIcon,
    VToolbarTitle,
    VToolbarItems,
    VApp,
    VChip,
    VFooter,
    VBreadcrumbs,
    VContent,
    VBreadcrumbsItem,
  },
  directives: {
    Ripple,
    Resize,
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      // custom global icons https://vuetifyjs.com/en/customization/icons#using-custom-icons
      sort: 'arrow_drop_up',
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#003399',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
})

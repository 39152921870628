import { render, staticRenderFns } from "./deleteBenchmark.vue?vue&type=template&id=71a0a54e&scoped=true&"
import script from "./deleteBenchmark.vue?vue&type=script&lang=js&"
export * from "./deleteBenchmark.vue?vue&type=script&lang=js&"
import style0 from "./deleteBenchmark.vue?vue&type=style&index=0&id=71a0a54e&lang=scss&scoped=true&"
import style1 from "./deleteBenchmark.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-6c7283b961/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a0a54e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-75fc87f20c/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VRow})

import { lazyLoadView } from '@baxter/web-vueapp-lib'

export default [
  {
    path: '/trueVue_analytics',
    pathToRegexpOptions: { strict: true },
    component: () => lazyLoadView(import('@plugins/trueVue-analytics/router/views/index')),
    meta: {
      authRequired: true,
      breadCrumb: {
        name: 'apps.trueVue_analytics.breadcrumb',
      },
    },
    children: [
      {
        path: '',
        name: 'trueVue_analytics_users',
        component: () => lazyLoadView(import('@plugins/trueVue-analytics/components/users')),
        meta: {
          breadCrumb: {
            name: 'apps.trueVue_analytics.users.breadcrumb',
          },
        },
      },
      {
        path: 'users',
        component: () => lazyLoadView(import('@plugins/trueVue-analytics/components/users')),
        meta: {
          breadCrumb: {
            name: 'apps.trueVue_analytics.users.breadcrumb',
          },
        },
      },
      {
        path: 'customers',
        name: 'trueVue_analytics_customers',
        component: () => lazyLoadView(import('@plugins/trueVue-analytics/components/customers')),
        meta: {
          breadCrumb: {
            name: 'apps.trueVue_analytics.customers.breadcrumb',
          },
        },
      },
      {
        path: 'benchmark',
        name: 'trueVue_analytics_benchmark',
        component: () => lazyLoadView(import('@plugins/trueVue-analytics/components/benchmark')),
        meta: {
          breadCrumb: {
            name: 'apps.trueVue_analytics.benchmark.breadcrumb',
          },
        },
      },
    ],
  },
]

<template>
  <div>
    <WebDialog ref="baseDialog" width="900px" height="450px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0 userHeaders">
            <h4>
              {{
                $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.available_users')
              }}
            </h4>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0 userHeaders">
            <h4>
              {{
                $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.assigned_users')
              }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0">
            <v-text-field
              v-model="searchQuery"
              solo
              class="elevation-0 search"
              data-test-id="search-available-users"
              :label="
                $t(
                  'apps.trueVue_analytics.customers.dialog_add_user_to_customer.search_available_users'
                )
              "
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="searchForUsersCheck"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-text-field
              :value="assignedUsersSearch"
              solo
              class="elevation-0 search"
              data-test-id="search-assigned-users"
              :label="
                $t(
                  'apps.trueVue_analytics.customers.dialog_add_user_to_customer.search_assigned_users'
                )
              "
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="(v) => (assignedUsersSearch = v)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card class="userLists mx-auto" elevation="0" outlined>
            <v-list>
              <v-list-item-group multiple>
                <template
                  v-for="(item, i) in filteredItems"
                  v-show="!item.is_user_assigned_to_customer"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="!item.is_user_assigned_to_customer"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== currentCountryCode"
                          class="add text-none"
                          data-test-id="add-button"
                          :input-value="active"
                          :true-value="item"
                          rounded
                          elevation="0"
                          @click="addUserToAssignedList(item)"
                        >
                          Add
                          <v-icon right>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="filteredItems.length === 0" class="justify-center">
                  <label v-if="!usersListLoading" class="noAssignedData">No records found</label>
                  <v-progress-circular
                    v-if="usersListLoading"
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-list-item>
                <v-list-item v-show="isUsersLoadMoreAvailable">
                  <v-btn
                    data-test-id="load-more-button"
                    block
                    text
                    color="primary"
                    :loading="usersListLoading"
                    @click="loadMoreUsers"
                    >{{
                      $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.load_more')
                    }}
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card class="userLists mx-auto" elevation="0" outlined>
            <div v-if="changesBeingMade" class="notSaved">
              <span>
                {{
                  $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.not_yet_saved')
                }}
              </span>
            </div>

            <v-list>
              <v-list-item-group multiple>
                <template
                  v-for="(item, i) in selectedFilteredItems"
                  v-show="item.is_user_assigned_to_customer"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                  <v-list-item
                    v-else-if="item.is_user_assigned_to_customer"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== currentCountryCode"
                          class="remove text-none"
                          :input-value="active"
                          :true-value="item"
                          data-test-id="remove-button"
                          rounded
                          elevation="0"
                          @click="removeUserFromAssignedList(item)"
                        >
                          Remove
                          <v-icon right>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="selectedFilteredItems.length === 0" class="justify-center">
                  <label v-if="!inputAccountSearch" class="noAssignedData">
                    {{
                      $t(
                        'apps.trueVue_analytics.customers.dialog_add_user_to_customer.no_assigned_users'
                      )
                    }}
                  </label>
                  <label v-if="inputAccountSearch" class="noAssignedData">
                    {{
                      $t(
                        'apps.trueVue_analytics.customers.dialog_add_user_to_customer.no_records_found'
                      )
                    }}
                  </label>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span>
          {{ $t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.button_save') }}
        </span>
      </template>
    </WebDialog>
    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'AssignUserToCustomer',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    customerDetails: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    searchOptions: {
      user_id: [],
    },
    items: [],
    selected: [],
    search: '',
    availableUsersSearch: '',
    assignedUsersSearch: '',
    selectedSearchFilters: { user_id: [] },
    userId: localStorage.getItem('user_id'),
    changesBeingMade: false,
    limit: 30,
    deletedFilters: [],
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
    usersPage: 2,
    usersListLoading: false,
    isUsersLoadMoreAvailable: false,
    searchQuery: '',
    searchTimeout: null,
  }),
  computed: {
    filteredItems() {
      return this._.orderBy(
        this.searchOptions.user_id.filter((item) => {
          if (!this.availableUsersSearch) {
            return this.searchOptions.user_id
          }
          return item.display
            ? item.display.toLowerCase().includes(this.availableUsersSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
    selectedFilteredItems() {
      return this._.orderBy(
        this.selectedSearchFilters.user_id.filter((item) => {
          if (!this.assignedUsersSearch) {
            return this.selectedSearchFilters.user_id
          }
          return item.display
            ? item.display.toLowerCase().includes(this.assignedUsersSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
  },
  methods: {
    async open() {
      this.$refs.loader.show()
      await this.loadFilters()
      this.$refs.loader.hide()
      const result = await this.$refs.baseDialog.show()
      if (result === true) {
        if (
          (this.selectedSearchFilters.user_id.length > 0 || this.deletedFilters.length > 0) &&
          this.changesBeingMade
        ) {
          const userData = {
            selectedSearchFilters: this.selectedSearchFilters.user_id,
            deletedFilters: this.deletedFilters,
          }
          return new Promise((resolve, reject) => {
            resolve(userData)
          })
        }
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
    async loadFilters() {
      try {
        this.usersPage = 2
        this.searchQuery = ''
        this.selectedSearchFilters.user_id = []
        this.searchOptions.user_id = []
        this.deletedFilters = []
        this.changesBeingMade = false
        const request = {
          params: {
            user_id: this.userId,
            _limit: this.limit,
            _page: 1,
            _orderBy: 'name',
          },
          customerkey: this.customerDetails.id,
        }

        this.customerDetails.users.forEach((element) => {
          this.selectedSearchFilters.user_id.push({
            display: `${element.first_name} ${element.last_name}`,
            value: element.user_id.toString(),
            is_user_assigned_to_customer: true,
            country_code: element.country,
          })
          this.deletedFilters.push({
            display: `${element.first_name} ${element.last_name}`,
            value: element.user_id.toString(),
            is_user_assigned_to_customer: true,
            country_code: element.country,
          })
        })

        const response = await this.getAssignedUser(request)
        if (response) {
          if (response.total_pages > 1) {
            this.isUsersLoadMoreAvailable = true
          }
          response.data.forEach((items) => {
            this.searchOptions.user_id.push({
              display: `${items.first_name} ${items.last_name}`,
              value: items.user_id.toString(),
              is_user_assigned_to_customer: items.is_user_assigned_to_customer
                ? items.is_user_assigned_to_customer
                : false,
              country_code: items.country_code,
            })
          })
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async loadMoreUsers() {
      try {
        this.changesBeingMade = false
        this.usersListLoading = true
        const request = {
          params: {
            user_id: this.userId,
            _limit: this.limit,
            _page: this.usersPage,
            _orderBy: 'name',
            search_term: this.searchQuery,
          },
          customerkey: this.customerDetails.id,
        }

        const response = await this.getAssignedUser(request)
        if (response) {
          response.data.forEach((items) => {
            const removedFromAssignedArray = this.selectedSearchFilters.user_id.filter(
              (user) => user.value === items.user_id
            )
            if (removedFromAssignedArray.length > 0) {
              items.is_user_assigned_to_customer = true
            } else {
              items.is_user_assigned_to_customer = false
            }
            this.searchOptions.user_id.push({
              display: `${items.first_name} ${items.last_name}`,
              value: items.user_id.toString(),
              is_user_assigned_to_customer: items.is_user_assigned_to_customer
                ? items.is_user_assigned_to_customer
                : false,
              country_code: items.country_code,
            })
          })
        }

        if (this.usersPage === response.total_pages) {
          this.isUsersLoadMoreAvailable = false
        }

        this.usersPage += 1
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.usersListLoading = false
    },

    async searchForUsersCheck() {
      const value = this.searchQuery
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchForUsers(value)
      }, 500)
    },

    async searchForUsers(value) {
      this.usersPage = 2
      this.searchOptions.user_id = []
      this.isUsersLoadMoreAvailable = false
      this.usersListLoading = true

      const request = {
        params: {
          user_id: this.userId,
          _limit: value ? 3000 : this.limit,
          _page: 1,
          _orderBy: 'name',
          search_term: value,
        },
        customerkey: this.customerDetails.id,
      }
      try {
        const response = await this.getAssignedUser(request)
        if (value !== this.searchQuery) {
          return
        }
        if (response) {
          if (response.total_pages > 1) {
            this.isUsersLoadMoreAvailable = true
          }

          response.data.forEach((items) => {
            const removedFromAssignedArray = this.selectedSearchFilters.user_id.filter(
              (user) => user.value === items.user_id
            )
            if (removedFromAssignedArray.length > 0) {
              items.is_user_assigned_to_customer = true
            } else {
              items.is_user_assigned_to_customer = false
            }
            this.searchOptions.user_id.push({
              display: `${items.first_name} ${items.last_name}`,
              value: items.user_id.toString(),
              is_user_assigned_to_customer: items.is_user_assigned_to_customer
                ? items.is_user_assigned_to_customer
                : false,
              country_code: items.country_code,
            })
          })
          this.usersListLoading = false
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
        this.usersListLoading = false
      }
    },
    addUserToAssignedList(selectedItem) {
      const currentListOfUsers = this.selectedSearchFilters.user_id
      if (currentListOfUsers.includes(selectedItem)) {
      } else {
        this.selectedSearchFilters.user_id.push({
          display: selectedItem.display,
          value: selectedItem.value,
          is_user_assigned_to_customer: true,
          country_code: selectedItem.country_code,
        })
        this.searchOptions.user_id.map((items) => {
          if (items.value === selectedItem.value) {
            items.is_user_assigned_to_customer = true
          }
        })
        this.changesBeingMade = true
      }
    },
    removeUserFromAssignedList(selectedItem) {
      const removedUser = selectedItem
      this.selectedSearchFilters.user_id = this.selectedSearchFilters.user_id.filter(
        (item) => item.value !== selectedItem.value
      )
      this.searchOptions.user_id.map((items) => {
        if (items.value === selectedItem.value) {
          items.is_user_assigned_to_customer = false
        }
      })

      this.changesBeingMade = true
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    .v-text-field__slot {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}

.search {
  max-width: 300px;
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}

::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

v.divider {
  border: none;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 700px;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.userLists {
  width: 400px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
  &:first-child {
  }
  &:last-child {
  }
  .v-list {
    padding-top: 0;
    .v-list-item {
      min-height: 43px;
      padding: 0 9px;
      .v-list-item__content {
        .v-list-item__title {
          color: #54585a;
          font-family: 'Noto Sans';
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

button.add {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 63px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

button.remove {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 87px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

.notSaved {
  background-color: #eff0f0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 9px;
  line-height: 19px;
  padding-left: 4px;
  color: #54585a;
}

.userHeaders {
  padding: 12px 12px 0;
}

.noAssignedData {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
}
</style>,

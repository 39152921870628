<template>
  <div>
    <WebPageContentLayout ref="contentLayout">
      <template v-slot:page-title>
        <v-row :class="$style.title">
          <v-col :class="$style.floatCol">
            <span :class="$style.pageTitle">
              {{ $t('apps.trueVue_analytics.page_title') }}
            </span>
          </v-col>
          <v-col
            v-if="isUserImpersonated"
            v-model="impersonationIsTrue"
            :class="$style.ifImpersonation"
          >
            <span>
              {{ $t('apps.trueVue_analytics.users.messages.impersonate_user_success_one') }}
              {{ impersonatedUserName }}
              {{ $t('apps.trueVue_analytics.users.messages.impersonate_user_success_second') }}
            </span>
          </v-col>
          <v-col
            v-if="isUserImpersonated"
            v-model="impersonationIsTrue"
            :class="$style.stopImpersonation"
          >
            <button :class="$style.stop" @click="stopImpersonation">
              <slot>
                {{ $t('apps.trueVue_analytics.users.impersonate_user.stop') }}
              </slot>
            </button>
          </v-col>
          <v-col :class="$style.iconImage">
            <img :class="$style.adminIcon" v-bind:src="iconImage" alt />
          </v-col>
        </v-row>
      </template>
      <template v-slot:page-content>
        <v-row no-gutters>
          <v-col>
            <WebTabs>
              <div class="tabRowSection">
                <div class="firstTabSection">
                  <WebTab class="n-pad" to="/trueVue_analytics/users">{{
                    $t('apps.trueVue_analytics.users.tab_title')
                  }}</WebTab>
                  <WebTab class="n-pad" to="/trueVue_analytics/customers">{{
                    $t('apps.trueVue_analytics.customers.tab_title')
                  }}</WebTab>
                  <WebTab class="n-pad" to="/trueVue_analytics/benchmark">{{
                    $t('apps.trueVue_analytics.benchmark.tab_title')
                  }}</WebTab>
                </div>
                <div class="secondTabSection">
                  <WebTab
                    class="google"
                    href="https://console.firebase.google.com/"
                    target="_blank"
                    to="/trueVue_analytics/google"
                    >{{ $t('apps.trueVue_analytics.google_analytics.tab_title') }}</WebTab
                  >
                </div>
              </div>
            </WebTabs>
            <router-view></router-view>
          </v-col>
        </v-row>
      </template>
    </WebPageContentLayout>
    <WebDataLoading ref="loader"></WebDataLoading>
    <ImpersonateUser
      v-if="impersonatedUserName"
      ref="removeUser"
      :user-impersonated-details="impersonatedUserName"
    ></ImpersonateUser>
  </div>
</template>,

<script>
import webClientAuth from '@baxter/web-client-auth'
import { EventBus } from '@src/event-bus.js'
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'

const Users = () => import('@plugins/trueVue-analytics/components/users')
const ImpersonateUser = () => import('@plugins/trueVue-analytics/components/impersonateUser')

export default {
  components: {
    Users,
    ImpersonateUser,
  },
  mixins: [trueVueMgmtStoreHelper],
  metaInfo() {
    return {
      title: this.$t('apps.trueVue_analytics.page_title'),
    }
  },
  data() {
    return {
      active_tab: '1',
      iconImage: null,
      impersonationIsTrue: false,
      isUserImpersonated: false,
      user_id: localStorage.getItem('user_id'),
      impersonatedUserName: null,
      globalAdmin: localStorage.getItem('globaladmin'),
      countryAdmin: localStorage.getItem('countryadmin'),
      country: localStorage.getItem('country'),
    }
  },
  computed: {
    alertMessage: {
      get() {
        return this.$appStoreHelper.alertMessage
      },
    },
  },
  watch: {
    alertMessage(message) {
      this.$refs.contentLayout.showAlert(message)
    },
  },
  created() {
    if (
      localStorage.getItem('impersonatedUser') != null &&
      localStorage.getItem('impersonatedUser') != ''
    ) {
      this.isUserImpersonated = true
      this.impersonationIsTrue = true
      this.impersonatedUserName = localStorage.getItem('impersonatedUser')
    } else {
      this.getMyprofile()
    }
    this.iconImage = null
    if (this.globalAdmin && (this.globalAdmin = true)) {
      this.iconImage = '/img/flags/021-globe.svg'
    } else if (this.countryAdmin && (this.countryAdmin = true)) {
      switch (this.country) {
        case 'US':
          this.iconImage = '/img/flags/020-flag.svg'
          break
        case 'AU':
          this.iconImage = '/img/flags/001-austria.svg'
          break
        case 'BE':
          this.iconImage = '/img/flags/002-belgium.svg'
          break
        case 'CN':
          this.iconImage = '/img/flags/015-china.svg'
          break
        case 'FR':
          this.iconImage = '/img/flags/019-france.svg'
          break
        case 'DE':
          this.iconImage = '/img/flags/017-germany.svg'
          break
        case 'IN':
          this.iconImage = '/img/flags/010-india.svg'
          break
        case 'ES':
          this.iconImage = '/img/flags/016-spain.svg'
          break
        case 'GB':
          this.iconImage = '/img/flags/012-uk.svg'
          break
      }
    } else {
      this.iconImage = null
    }
  },
  mounted() {
    EventBus.$on('impersonatedFullName', this.onImpersonatedFullName)
  },
  methods: {
    onImpersonatedFullName(impersonatedUserResult) {
      this.isUserImpersonated = true
      localStorage.setItem('impersonatedUser', impersonatedUserResult)
      this.impersonatedUserName = impersonatedUserResult
    },
    async getMyprofile() {
      try {
        const request = {
          params: {
            user_id: this.user_id,
          },
        }
        const profileInfo = await this.getUserProfile(request)
        if (profileInfo.data.impersonated_as != null && profileInfo.data.impersonated_as != '') {
          this.isUserImpersonated = true
          this.impersonatedUserName = profileInfo.data.admin_fullname
          localStorage.setItem('impersonatedUser', this.impersonatedUserName)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async stopImpersonation() {
      try {
        const result = await this.$refs.removeUser.open()
        if (result !== false) {
          this.$refs.loader.show()
          const request = {
            data: {
              impersonating_user_id: null,
            },
            params: {
              user_id: this.user_id,
            },
          }
          await this.updateUserImpersonation(request)
          this.$refs.loader.hide()
          this.isUserImpersonated = false
          localStorage.removeItem('impersonated_user')
          localStorage.removeItem('impersonatedUser')
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
  },
}
</script>,

<style lang="scss" module>
.floatCol {
  padding-top: 0px;
  padding-bottom: 0px;
  width: auto;
}

.adminIcon {
  width: 35px;
  height: 100%;
  margin-right: 10px;
  float: right;
}

.title {
  padding: 0px 12px;
}

.pageTitle {
  vertical-align: middle;
  color: #001a72;
  font-family: 'Noto Sans Bold', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
}

.pageTabTitle .v-tab--active {
  color: #001a72 !important;
}

.pageTabTitle {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}

::v-deep .v-tab--active {
  color: #001a72 !important;
}

::v-deep .v-tabs-slider-wrapper {
  .v-tabs-slider {
    background-color: #001a72 !important;
  }
}

.ifImpersonation,
.stopImpersonation {
  padding: 5px;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
  flex: none;
  width: auto;
}

.stopImpersonation {
  margin-left: 5px;
  background: #003399;
  border-radius: 20px;
}

.iconImage {
  text-align: right;
  padding-top: 0px;
  padding-bottom: 0px;
  width: auto;
}

.stop {
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  padding: 2px 11px 0px;
  color: #ffffff !important;
}
</style>,

<style type="text/css">
.firstTabSection {
  display: flex !important;
}

.secondTabSection {
  display: flex !important;
}

.google {
  color: #001a72 !important;
}

.v-tab--active {
  color: #001a72 !important;
}

.tabRowSection {
  display: flex !important;
  justify-content: space-between !important;
  flex: 1;
}

.container.container--fluid {
  margin-top: 5px;
  margin-bottom: 60px;
}

.n-pad {
  padding: 0px;
}

hr.v-divider.theme--light {
  display: none !important;
}

.v-card__title {
  padding-bottom: 0px !important;
  padding-left: 34px !important;
  padding-right: 27px !important;
  padding-top: 20px !important;
}

.container {
  padding-top: 0;
  padding-bottom: 0;
}

.v-card__text {
  padding-top: 5px !important;
}
</style>,

import Vue from 'vue'
import WebDataGrid from '@baxter/web-data-grid'
import WebClientAuth from '@baxter/web-client-auth'
import WebDataExport from '@baxter/web-data-export'
import WebVueAppLib from '@baxter/web-vueapp-lib'

import TrueVueMgmtPlugin from '@plugins/trueVue-analytics'

export default function configurePlugins(options) {
  Vue.use(WebVueAppLib, options)
  Vue.use(WebDataGrid, options)
  Vue.use(WebDataExport, options)
  Vue.use(WebClientAuth, options)
  // store admin email in local storage from the profile
  WebClientAuth.getProfile().then((response) => {
    if (response) {
      localStorage.setItem('user_id', response.eid)
      if (response.globaladmin_perms && response.globaladmin_perms !== null) {
        localStorage.setItem('globaladmin', true)
      }
      if (response.countryadmin_perms && response.countryadmin_perms !== null) {
        localStorage.setItem('countryadmin', true)
        localStorage.setItem('country', response.locale)
      }
    }
  })

  // install domain plugin
  Vue.use(TrueVueMgmtPlugin, options)
}

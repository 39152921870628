<template>
  <div>
    <CustomersAdvancedFilters :selected-data-filters.sync="dataFilters" />

    <WebDataGrid
      class="no_alternate_row_color no_special_row_hover"
      :grid-config="gridConfig"
      :selected-rows.sync="selectedRows"
      @refresh-grid-event="refreshGrid"
    >
      <template v-slot:actions-header>
        <v-list single-line>
          <v-list-item :disabled="disableCreateOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="create-benchmark-button" @click="createBenchmark">{{
                $t('apps.trueVue_analytics.customers.create_benchmark')
              }}</WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list single-line>
          <v-list-item :disabled="disableMenuOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="edit-benchmark-button" @click="editBenchmark">{{
                $t('apps.trueVue_analytics.customers.edit_benchmark')
              }}</WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list v-if="globalAdmin" single-line>
          <v-list-item v-if="true" :disabled="disableFlaggingOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="flag-benchmark-button" @click="flagBenchmark">{{
                $t('apps.trueVue_analytics.customers.flag_benchmark')
              }}</WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list single-line>
          <v-list-item :disabled="disableMenuOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="assign-user-button" @click="assignUserToCustomer">{{
                $t('apps.trueVue_analytics.customers.add_user')
              }}</WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:actions-footer></template>

      <template v-slot:item.users="{ item }">
        <AssociatedUsers :items="item.users" />
      </template>
      <template v-slot:item.jde_id="{ item }">
        <div class="rowSize">
          <template v-for="jdeId in item.jde_id">
            <v-chip :key="jdeId" class="chips">{{ jdeId }}</v-chip>
          </template>
        </div>
      </template>
      <template v-slot:item.benchmarks="{ item }">
        <div class="rowSize">
          <template v-for="benchmark in item.benchmarks">
            <span :key="benchmark">{{ benchmark }}</span>
          </template>
        </div>
      </template>
      <template v-slot:item.date_created="{ item }">{{
        item.date_created ? formatDate(item.date_created) : '-'
      }}</template>
      <template v-slot:item.is_flagged_for_benchmark="{ item }">{{
        item.is_flagged_for_benchmark ? 'Yes' : 'No'
      }}</template>
      <template v-slot:item.date_updated="{ item }">{{
        item.date_created ? formatDateWithTime(item.date_created) : '-'
      }}</template>
    </WebDataGrid>

    <WebConfirmDialog ref="confirm">
      <template v-slot:dialog-icon>
        <v-icon color="#BE531C" x-large>info</v-icon>
      </template>
    </WebConfirmDialog>

    <WebDataLoading ref="loader"></WebDataLoading>

    <AssignUserToCustomer
      ref="updateUser"
      :customer-details="selectedRows[0]"
    ></AssignUserToCustomer>
    <CreateBenchmark ref="createNewBenchmark" :customer-details="selectedRows"></CreateBenchmark>
    <EditAssignedBenchmark
      ref="editBenchmark"
      :customer-details="selectedRows[0]"
    ></EditAssignedBenchmark>
  </div>
</template>,

<script>
import { formatDateTimeRelative, decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import moment from 'moment'
import { filter } from 'minimatch'

const CustomersAdvancedFilters = () =>
  import('@plugins/trueVue-analytics/components/advanced-filters/customers')
const AssignUserToCustomer = () =>
  import('@plugins/trueVue-analytics/components/assignUserToCustomer')
const CreateBenchmark = () =>
  import('@plugins/trueVue-analytics/components/createBenchmarkFromCustomerList')
const EditAssignedBenchmark = () =>
  import('@plugins/trueVue-analytics/components/editAssignedBenchmark')
const AssociatedUsers = () => import('@plugins/trueVue-analytics/components/association-list/users')

export default {
  name: 'Customers',
  components: {
    CustomersAdvancedFilters,
    AssignUserToCustomer,
    CreateBenchmark,
    EditAssignedBenchmark,
    AssociatedUsers,
  },
  mixins: [trueVueMgmtStoreHelper],
  data: () => ({
    dataFilters: {},
    selected: [],
    selectedRows: [],
    user_id: localStorage.getItem('user_id'),
    gridConfig: {
      records: [],
      loading: true,
      itemKey: 'name',
      highlightKey: 'id',
      tableHeight: '100%',
      options: {
        itemsPerPageOptions: [10, 25, 50, 100],
        totalPages: 0,
        itemsPerPage: 10,
        page: 1,
        sortBy: 'name',
        descending: false,
      },
      headers: [
        {
          text: '__HEADER__MENU__',
          value: `__HEADER__MENU__`,
          sortable: false,
          fixed: true,
          width: '1px',
        },
        {
          text: 'Account Name',
          value: `name`,
          sortable: true,
          fixed: true,
          class: 'customername',
        },
        {
          text: 'Customer Key',
          value: 'id',
          sortable: true,
          fixed: true,
          class: 'customerkey',
        },
        {
          text: 'State',
          value: 'state',
          sortable: true,
          fixed: true,
          class: 'state',
        },
        {
          text: 'Country',
          value: 'country_code',
          sortable: true,
          fixed: true,
          class: 'country',
        },
        {
          text: 'Flagged',
          value: 'is_flagged_for_benchmark',
          sortable: false,
          fixed: true,
          class: 'flagged',
        },
        {
          text: 'Current Benchmark',
          value: 'benchmarks',
          sortable: false,
          fixed: true,
          class: 'benchmark',
        },
        {
          text: 'Users',
          value: 'users',
          sortable: false,
          fixed: true,
          class: 'users',
        },
        {
          text: 'Date Created',
          value: 'date_created',
          sortable: true,
          fixed: true,
          class: 'date',
        },
        {
          text: 'Last Modified',
          value: 'date_updated',
          sortable: false,
          fixed: true,
          class: 'recentData',
        },
      ],
    },
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
    data: {
      users: [],
    },
  }),
  created() {
    this.gridConfig.headers.filter((a) => a.value === 'state')[0].text = this.$t(
      'apps.trueVue_analytics.customers.grid.columns.state'
    )
  },
  computed: {
    disableMenuOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length !== 1 ||
          !this.selectedRows.some((item) => item.country_code === this.currentCountryCode)
        )
      }
      return this.selectedRows.length !== 1
    },
    disableFlaggingOption() {
      return this.selectedRows.length !== 1
    },
    disableCreateOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length === 0 ||
          !this.selectedRows.some(
            (item) => item.is_flagged_for_benchmark && item.country_code === this.currentCountryCode
          ) ||
          !this.selectedRows.every((item) => {
            if (item.is_flagged_for_benchmark === true) {
              return true
            }
          })
        )
      }
      return (
        this.selectedRows.length === 0 ||
        !this.selectedRows.some((item) => item.is_flagged_for_benchmark) ||
        !this.selectedRows.every((item) => {
          if (item.is_flagged_for_benchmark === true) {
            return true
          }
        })
      )
    },
    disableEditMenuOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length !== 1 ||
          !this.selectedRows.some(
            (item) => item.is_flagged_for_benchmark && item.country_code === this.currentCountryCode
          )
        )
      }
      return (
        this.selectedRows.length !== 1 ||
        !this.selectedRows.some((item) => item.is_flagged_for_benchmark)
      )
    },
  },
  watch: {
    async dataFilters(filters) {
      this.dataFilters = filters
      this.gridConfig.options.page = 1
      await this.refreshGrid()
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).format('ll')
    },
    formatDateWithTime(value) {
      return moment(value).format('ll | LT')
    },
    async createBenchmark() {
      try {
        const result = await this.$refs.createNewBenchmark.open()
        if (result) {
          this.$refs.loader.show()
          const benchmarkName = result.benchmark_name
          const benchmarkAbbrev = result.legend_abbreviation
          const customersArray = []
          const benchmarkCountry = result.benchmark_country_code
          const benchmarkType = this.globalAdmin ? 'global' : 'country'
          result.customers.map((item) => {
            customersArray.push(item.id)
          })
          const request = {
            data: {
              entry_set: {
                benchmark_name: benchmarkName,
                legend_abbreviation: benchmarkAbbrev,
                customers: customersArray,
                benchmark_country_code: benchmarkCountry,
                benchmark_type: benchmarkType,
              },
            },
          }
          const response = await this.createNewBenchmark(request)
          this.$refs.loader.hide()
          this.$publishAlert(
            benchmarkName +
              this.$t('apps.trueVue_analytics.customers.messages.benchmark_created_success'),
            'success'
          )
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async flagBenchmark() {
      try {
        this.$refs.loader.show()
        const request = {
          data: {
            is_flagged_for_benchmark: !this.selectedRows[0].is_flagged_for_benchmark,
          },
          params: {
            customerkey: this.selectedRows[0].id,
          },
        }
        const response = await this.updateCustomerProfileForAssociation(request)
        this.$refs.loader.hide()
        if (response) {
          if (this.selectedRows[0].is_flagged_for_benchmark === false) {
            this.$publishAlert(
              this.selectedRows[0].name +
                this.$t('apps.trueVue_analytics.customers.messages.flagged_for_benchmark_success'),
              'success'
            )
          } else {
            this.$publishAlert(
              this.selectedRows[0].name +
                this.$t(
                  'apps.trueVue_analytics.customers.messages.unflagged_for_benchmark_success'
                ),
              'success'
            )
          }
          this.selectedRows[0].is_flagged_for_benchmark = response.is_flagged_for_benchmark
            ? !this.selectedRows[0].is_flagged_for_benchmark
            : response.is_flagged_for_benchmark
        }
        await this.refreshGrid()
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async assignUserToCustomer() {
      try {
        const result = await this.$refs.updateUser.open()
        const userArrayObj = {
          deleteUsersArray: [],
          addUsersArray: [],
        }
        if (result !== false) {
          this.$refs.loader.show()
          if (!this.globalAdmin) {
            userArrayObj.deleteUsersArray = result.deletedFilters.filter(
              (item) => item.country_code === this.currentCountryCode
            )
          } else {
            userArrayObj.deleteUsersArray = result.deletedFilters
          }
          result.selectedSearchFilters.map((item) => {
            if (!this.globalAdmin) {
              if (item.country_code === this.currentCountryCode) {
                userArrayObj.addUsersArray.push(item.value)
              }
            } else {
              userArrayObj.addUsersArray.push(item.value)
            }
          })

          if (userArrayObj.deleteUsersArray.length > 0) {
            const deleteUsersArray = []
            userArrayObj.deleteUsersArray.map((item) => {
              deleteUsersArray.push(item.value.toString())
            })
            const request = {
              data: {
                users: deleteUsersArray,
              },
              params: {
                customer: this.selectedRows[0].id,
              },
            }
            const response = await this.deleteUserForAssignment(request)

            if (userArrayObj.addUsersArray.length > 0) {
              this.assignUsersToCustomersAPIRequest(userArrayObj.addUsersArray)
            } else if (response) {
              await this.assignUsersSuccessMessage()
            }
          } else if (userArrayObj.addUsersArray.length > 0) {
            this.assignUsersToCustomersAPIRequest(userArrayObj.addUsersArray)
          }
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async assignUsersToCustomersAPIRequest(userArray) {
      const request = {
        data: {
          users: userArray,
        },
        params: {
          customer: this.selectedRows[0].id,
        },
      }
      const response = await this.addUserForAssignment(request)
      if (response) {
        await this.assignUsersSuccessMessage()
      }
    },
    async assignUsersSuccessMessage() {
      this.$refs.loader.hide()
      this.$publishAlert(
        this.selectedRows[0].name +
          this.$t('apps.trueVue_analytics.customers.messages.customer_updated_successfully'),
        'success'
      )
      await this.refreshGrid()
    },
    async editBenchmark() {
      try {
        const result = await this.$refs.editBenchmark.open()
        if (result !== false) {
          const benchmarkName =
            result.length > 0 ? result[0].display : this.selectedRows[0].benchmarks[0]
          if (benchmarkName) {
            this.$refs.loader.show()
            const isFlaggedForView = result.length > 0
            const request = {
              params: {
                customerkey: this.selectedRows[0].id,
              },
              data: {
                benchmark_name: benchmarkName,
                is_flagged_for_view: isFlaggedForView,
              },
            }
            const response = await this.updateCustomerProfileForBenchmarkAssociation(request)
            if (response) {
              this.$refs.loader.hide()
              this.$publishAlert(
                this.selectedRows[0].name +
                  this.$t('apps.trueVue_analytics.customers.messages.benchmark_edited_success'),
                'success'
              )
              await this.refreshGrid()
            }
          }
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async refreshGrid(
      pageSize = this.gridConfig.options.itemsPerPage,
      pageNumber = this.gridConfig.options.page,
      sortBy = this.gridConfig.itemKey
    ) {
      try {
        let orderBy = this.gridConfig.itemKey
        let descending = false
        if (this.gridConfig.options.sortBy.length > 0) {
          orderBy = this.gridConfig.options.sortBy[0]
        }
        if (this.gridConfig.options.sortBy.length > 0) {
          descending = this.gridConfig.options.sortDesc[0]
        }
        const request = {
          params: {
            _limit: pageSize,
            _page: pageNumber,
            _orderBy: orderBy,
            _ascending: !descending,
          },
          headers: {},
        }
        const tempDataFilters = {
          users: JSON.stringify([]),
          country: JSON.stringify([]),
        }

        if (
          !(
            Object.entries(this.dataFilters).length === 0 && this.dataFilters.constructor === Object
          )
        ) {
          Object.keys(this.dataFilters).forEach((key, index) => {
            tempDataFilters[key] =
              typeof this.dataFilters[key] === 'string'
                ? this.dataFilters[key]
                : JSON.stringify(this.dataFilters[key])
          })
        }

        request.params = Object.assign(request.params, tempDataFilters)
        this.gridConfig.loading = true
        const response = await this.getCustomers(request)
        response.data.map((parentObj) => {
          if (parentObj.users.length > 0) {
            parentObj.users.map((childObj) => {
              childObj.user_name = `${childObj.first_name} ${childObj.last_name}`
            })
          }
        })

        this.gridConfig.options.totalPages = response.total_pages
        this.gridConfig.records = response.data
        this.gridConfig.options.totalItems = response.total
        this.gridConfig.options.page = response._page
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.gridConfig.loading = false
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep i.v-icon {
  color: #001a72 !important;
}

::v-deep th {
  white-space: nowrap;
}

::v-deep th.text-start {
  padding: 3px 5px;
  background-color: rgba(84, 88, 90, 0.1) !important;
  span {
    color: #001a72;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-align: right;
    &:hover {
      color: #001a72;
    }
  }
}

::v-deep td.text-start {
  padding: 3px 5px;
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  height: 54px;
  max-height: 54px;
  &:nth-child(1) {
    padding-left: 20px;
  }
  &:nth-child(2) {
    padding-left: 20px;
  }
  &:nth-child(8) {
    max-width: 200px;
  }
}
::v-deep th.customername {
  min-width: 143px;
}
::v-deep th.customerkey {
  min-width: 124px;
}
::v-deep th.country {
  min-width: 84px;
}
::v-deep th.date {
  min-width: 118px;
}
::v-deep th.jdeId {
  min-width: 66px;
}
::v-deep th.flagged {
  min-width: 83px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}
::v-deep th.benchmark {
  min-width: 164px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}
::v-deep th.users {
  min-width: 77px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}
::v-deep th.recentData {
  min-width: 150px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}
::v-deep .v-data-table__wrapper {
  border-top: none !important;
}

::v-deep .v-simple-checkbox {
  i.v-icon {
    height: 12px;
    width: 12px;
    color: gray !important;
  }
}

.rowSize {
  max-height: 47px;
  overflow-y: auto;
}

.chips {
  margin: 1px 2px 1px 0;
  border-radius: 30px;
  background-color: #edeeee;
  span {
    color: #54585a;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
}

.v-list-item {
  padding: 0 30px;
  .v-list-item__title {
    color: #4066b3;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
}
.v-chip.v-size--default {
  height: 22px;
  font-size: 12px;
}

.v-list {
  padding: 0px;
  &:first-child {
    padding-top: 15px;
  }
  &:last-child {
    padding-bottom: 15px;
  }
}

::v-deep .v-data-table-header .v-simple-checkbox {
  display: none;
}
</style>,

<style type="text/css">
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menuable__content__active {
    width: 241px;
  }
}
</style>,

import { render, staticRenderFns } from "./impersonateUser.vue?vue&type=template&id=5a1f9895&scoped=true&"
import script from "./impersonateUser.vue?vue&type=script&lang=js&"
export * from "./impersonateUser.vue?vue&type=script&lang=js&"
import style0 from "./impersonateUser.vue?vue&type=style&index=0&id=5a1f9895&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-6c7283b961/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a1f9895",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-75fc87f20c/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})

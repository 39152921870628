<template>
  <div>
    <UsersAdvancedFilters :selected-data-filters.sync="dataFilters" />
    <WebDataGrid
      class="no_alternate_row_color no_special_row_hover"
      :grid-config="gridConfig"
      :selected-rows.sync="selectedRows"
      @refresh-grid-event="refreshGrid"
    >
      <template v-slot:actions-header>
        <v-list single-line>
          <v-list-item ml :disabled="disableMenuOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="assign-customer-button" @click="assignCustomerToUser">
                {{ $t('apps.trueVue_analytics.users.assign_customer_to_user.assign_customer') }}
              </WebLinkButton>
            </v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="disableImpersonationOption">
            <v-list-item-title>
              <WebLinkButton data-test-id="impersonate-user-button" @click="impersonateUser">
                {{ $t('apps.trueVue_analytics.users.impersonate_user.impersonate') }}
              </WebLinkButton>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:actions-footer> </template>

      <template v-slot:item.customers="{ item }">
        <AssociatedCustomers :items="item.customers" />
      </template>
      <template v-slot:item.created_ts="{ item }">
        {{ formatDate(item.created_ts) }}
      </template>
    </WebDataGrid>

    <WebConfirmDialog ref="confirm">
      <template v-slot:dialog-icon>
        <v-icon color="#BE531C" x-large> info</v-icon>
      </template>
    </WebConfirmDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
    <AssignCustomerToUser
      ref="updateCustomer"
      :user-details="selectedRows[0]"
    ></AssignCustomerToUser>
    <ImpersonateUser ref="changeUser" :user-details="selectedRows[0]"></ImpersonateUser>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { formatDateTimeRelative, decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'
import { EventBus } from '@src/event-bus.js'
import moment from 'moment'
import { filter } from 'minimatch'

const UsersAdvancedFilters = () =>
  import('@plugins/trueVue-analytics/components/advanced-filters/users')
const AssignCustomerToUser = () =>
  import('@plugins/trueVue-analytics/components/assignCustomerToUser')
const ImpersonateUser = () => import('@plugins/trueVue-analytics/components/impersonateUser')
const AssociatedCustomers = () =>
  import('@plugins/trueVue-analytics/components/association-list/customers')

export default {
  name: 'Users',
  components: {
    UsersAdvancedFilters,
    AssignCustomerToUser,
    ImpersonateUser,
    AssociatedCustomers,
  },
  mixins: [trueVueMgmtStoreHelper],
  data: () => ({
    dataFilters: {},
    selectedRows: [],
    user_id: localStorage.getItem('user_id'),
    gridConfig: {
      records: [],
      loading: true,
      tableHeight: '100%',
      itemKey: 'user_id',
      highlightKey: 'user_id',
      options: {
        itemsPerPageOptions: [10, 25, 50, 100],
        totalPages: 0,
        itemsPerPage: 10,
        page: 1,
        sortBy: 'user_id',
        descending: false,
      },
      headers: [
        {
          text: '__HEADER__MENU__',
          value: `__HEADER__MENU__`,
          sortable: false,
          fixed: true,
          width: '1px',
        },
        {
          text: 'First Name',
          value: `first_name`,
          sortable: true,
          fixed: true,
          class: 'first-name',
        },
        {
          text: 'Last Name',
          value: 'last_name',
          sortable: true,
          fixed: true,
          class: 'last-name',
        },
        {
          text: 'Employee ID',
          value: 'user_id',
          sortable: true,
          fixed: true,
          class: 'employee-id',
        },
        {
          text: 'Email',
          value: 'email',
          sortable: true,
          fixed: true,
          class: 'email',
        },
        {
          text: 'Country',
          value: 'country_code',
          sortable: true,
          fixed: true,
          class: 'country',
        },
        {
          text: 'Accounts',
          value: 'customers',
          sortable: false,
          fixed: true,
          class: 'accounts',
        },
        {
          text: 'Tier',
          value: 'user_type',
          sortable: false,
          fixed: true,
          class: 'tier',
        },
        {
          text: 'Date Created',
          value: 'created_ts',
          sortable: true,
          fixed: true,
          class: 'date',
        },
      ],
    },
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
  }),
  computed: {
    disableMenuOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length !== 1 ||
          !this.selectedRows.some((item) => item.country_code === this.currentCountryCode)
        )
      }
      return this.selectedRows.length !== 1
    },
    disableImpersonationOption() {
      if (!this.globalAdmin) {
        return (
          this.selectedRows.length !== 1 ||
          !this.selectedRows.some((item) => item.user_type !== 'Global Admin') ||
          !this.selectedRows.some((item) => item.user_type !== 'Country Admin') ||
          !this.selectedRows.some((item) => item.country_code === this.currentCountryCode) ||
          this.selectedRows.some((item) => item.user_id.toString() === this.user_id)
        )
      }
      return (
        this.selectedRows.length !== 1 ||
        this.selectedRows.some((item) => item.user_id.toString() === this.user_id) ||
        !this.selectedRows.some((item) => item.user_type !== 'Global Admin')
      )
    },
  },
  watch: {
    async dataFilters(filters) {
      this.dataFilters = filters
      this.gridConfig.options.page = 1
      await this.refreshGrid()
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).format('MMM DD, YYYY')
    },
    async assignCustomerToUser() {
      try {
        const result = await this.$refs.updateCustomer.open()
        const customerArrayObj = {
          deleteCustomersArray: [],
          addCustomersArray: [],
        }
        if (result !== false) {
          this.$refs.loader.show()
          if (!this.globalAdmin) {
            customerArrayObj.deleteCustomersArray = result.deletedFilters.filter(
              (item) => item.country_code === this.currentCountryCode
            )
          } else {
            customerArrayObj.deleteCustomersArray = result.deletedFilters
          }
          result.selectedSearchFilters.map((item) => {
            if (!this.globalAdmin) {
              if (item.country_code === this.currentCountryCode) {
                customerArrayObj.addCustomersArray.push(item.value)
              }
            } else {
              customerArrayObj.addCustomersArray.push(item.value)
            }
          })
          if (customerArrayObj.deleteCustomersArray.length > 0) {
            const deleteCustomersArray = []
            customerArrayObj.deleteCustomersArray.map((item) => {
              deleteCustomersArray.push(item.value)
            })
            const request = {
              data: {
                customers: deleteCustomersArray,
              },
              params: {
                user: this.selectedRows[0].user_id,
              },
            }
            const response = await this.deleteCustomerForAssignment(request)
            if (customerArrayObj.addCustomersArray.length > 0) {
              this.assignCustomersToUsersAPIRequest(customerArrayObj.addCustomersArray)
            } else if (response) {
              await this.assignCustomersSuccessMessage()
            }
          } else if (customerArrayObj.addCustomersArray.length > 0) {
            this.assignCustomersToUsersAPIRequest(customerArrayObj.addCustomersArray)
          }
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async assignCustomersToUsersAPIRequest(customerArray) {
      const request = {
        data: {
          customers: customerArray,
        },
        params: {
          user: this.selectedRows[0].user_id,
        },
      }
      const response = await this.addCustomerForAssignment(request)
      if (response) {
        await this.assignCustomersSuccessMessage()
      }
    },
    async assignCustomersSuccessMessage() {
      const fullName = `${this.selectedRows[0].first_name} ${this.selectedRows[0].last_name}`
      this.$refs.loader.hide()
      this.$publishAlert(
        `${fullName}'s ${this.$t(
          'apps.trueVue_analytics.users.messages.user_updated_successfully'
        )}`,
        'success'
      )
      await this.refreshGrid()
    },
    async impersonateUser() {
      try {
        const result = await this.$refs.changeUser.open()
        if (result !== false) {
          this.$refs.loader.show()
          const impersonatedUserId = result[0].value
          const request = {
            params: {
              user_id: this.user_id,
            },
            data: {
              impersonating_user_id: impersonatedUserId,
            },
          }
          const response = await this.updateUserImpersonation(request)
          if (response) {
            localStorage.setItem('impersonated_user', response.data.admin_fullname)
          }
          this.$refs.loader.hide()
          const impersonatedUserFullName = `${this.selectedRows[0].first_name} ${this.selectedRows[0].last_name}`
          EventBus.$emit('impersonatedFullName', impersonatedUserFullName)
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async refreshGrid(
      pageSize = this.gridConfig.options.itemsPerPage,
      pageNumber = this.gridConfig.options.page,
      sortBy = this.gridConfig.itemKey,
      mustSort = true
    ) {
      try {
        let orderBy = this.gridConfig.itemKey
        let descending = null

        if (this.gridConfig.options.sortBy.length > 0) {
          orderBy = this.gridConfig.options.sortBy[0]
        }
        if (this.gridConfig.options.sortBy.length > 0) {
          descending = this.gridConfig.options.sortDesc[0]
        }

        const request = {
          params: {
            _limit: pageSize,
            _page: pageNumber,
            _orderBy: orderBy,
            _ascending: !descending,
          },
        }

        const tempDataFilters = {
          user_role: JSON.stringify([]),
          customers: JSON.stringify([]),
          country: JSON.stringify([]),
        }
        if (
          !(
            Object.entries(this.dataFilters).length === 0 && this.dataFilters.constructor === Object
          )
        ) {
          Object.keys(this.dataFilters).forEach((key, index) => {
            tempDataFilters[key] =
              typeof this.dataFilters[key] === 'string'
                ? this.dataFilters[key]
                : JSON.stringify(this.dataFilters[key])
          })
        }
        request.params = Object.assign(request.params, tempDataFilters)
        this.gridConfig.loading = true
        const response = await this.getUsers(request)
        this.gridConfig.options.totalPages = response.total_pages
        response.data.forEach((obj) => {
          switch (obj.user_type) {
            case 'global':
              obj.user_type = 'Global Admin'
              break
            case 'country':
              obj.user_type = 'Country Admin'
              break
            case 'salesrep':
              obj.user_type = 'Sales Rep'
              break

            default:
              break
          }
        })
        this.gridConfig.records = response.data
        this.gridConfig.options.totalItems = response.total
        this.gridConfig.options.page = response._page
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.gridConfig.loading = false
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep i.v-icon {
  color: #001a72 !important;
}

::v-deep th.text-start {
  padding: 3px 5px;
  background-color: rgba(84, 88, 90, 0.1) !important;
  span {
    color: #001a72;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-align: right;
    &:hover {
      color: #001a72;
    }
  }
}

::v-deep td.text-start {
  padding: 3px 5px;
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  height: 54px;
  &:nth-child(1) {
    padding-left: 20px;
  }
  &:nth-child(8) {
    max-width: 200px;
  }
}

::v-deep .v-simple-checkbox {
  i.v-icon {
    height: 12px;
    width: 12px;
    color: gray !important;
  }
}

::v-deep th {
  white-space: nowrap;
}

::v-deep th.first-name {
  min-width: 104px;
}

::v-deep th.last-name {
  min-width: 102px;
}

::v-deep th.email {
  min-width: 60px;
}

::v-deep th.employee-id {
  min-width: 114px;
}

::v-deep th.country {
  min-width: 84px;
}

::v-deep th.accounts {
  min-width: 90px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}

::v-deep th.tier {
  min-width: 54px;
  color: #001a72 !important;
  font-weight: bold;
  font-size: 0.857em;
  height: 4.857em;
}

::v-deep th.date {
  min-width: 118px;
}

::v-deep tr:hover {
  height: auto;
  background: transparent !important;
}

::v-deep .v-data-table-header .v-simple-checkbox {
  display: none;
}

::v-deep .v-data-table__wrapper {
  border-top: none !important;
}

.v-list {
  padding: 0px;
  &:first-child {
    padding-top: 15px;
  }
  &:last-child {
    padding-bottom: 15px;
  }
}

.v-list-item {
  padding: 0 30px;
  .v-list-item__title {
    color: #4066b3;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    line-height: 19px;
  }
}
</style>,

<style type="text/css">
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menuable__content__active {
    width: 215px;
  }
}
</style>,

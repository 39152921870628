import axios from 'axios'
import webClientAuth from '@baxter/web-client-auth'
import Vue from 'vue'
import _ from 'lodash'

Object.defineProperty(Vue.prototype, '_', { value: _ })
// ============================
// State
// ============================
export const state = {
  /**
   * property "persist" indicates that persist the state of this module in "localStorage"
   */
  persist: false,
  users: [],
  customers: [],
  admins: [],
}

// ============================
// Getters
// ============================

export const getters = {
  users(state) {
    return state.users
  },
  customers(state) {
    return state.customers
  },
  admins(state) {
    return state.admins
  },
}

// ============================
// Actions
// ============================
export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, commit }) {},

  async loadSchema({ commit, state, rootState }) {
    if (state.users.length === 0 || state.customers.length === 0) {
      // combine all the calls for concurrent execution
      const result = await axios.all([
        webClientAuth.httpRequest(
          'GET',
          // `${Vue.$APP_CONFIG.api_base_url}/users/status?_orderBy=value`
          `${this._vm.$APP_CONFIG.api_base_url}/users/status?_orderBy=value`
        ),
        webClientAuth.httpRequest(
          'GET',
          // `${Vue.$APP_CONFIG.api_base_url}/users/types`
          `${this._vm.$APP_CONFIG.api_base_url}/users/types`
        ),
      ])
    }
  },

  /**
   * Get sales rep with post API call
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getUsersList({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getUsersList_api_url}`
    const response = await webClientAuth.httpRequest('GET', endPoint, request)
    return response.data
  },

  /**
   * Get Assigned sales rep with post API call
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getAssignedUserList({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getAssignedUserList_api_url}${request.customerkey}/relationships/users`
    const response = await webClientAuth.httpRequest('GET', endPoint, {
      params: request.params,
    })
    return response.data
  },
  /**
   * Get Assigned hospital with post API call
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getAssignedCustomerList({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getAssignedCustomerList_api_url}${request.selectedUserId}/relationships/customers`
    const response = await webClientAuth.httpRequest('GET', endPoint, request)
    return response.data
  },
  /**
   * Get hospitals data with post API call
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getCustomersList({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getCustomersList_api_url}`
    const response = await webClientAuth.httpRequest('GET', endPoint, request)
    return response.data
  },
  /**
   * Get benchmarks data with get API call
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getBenchmarksList({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getBenchmarksList_api_url}`
    const response = await webClientAuth.httpRequest('GET', endPoint, request)
    return response.data
  },

  async addUserForAssignment({ commit, state, rootState }, requestAdd) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateUsersToCustomer_api_url}${requestAdd.params.customer}/relationships/users`
    const response = await webClientAuth.httpRequest('POST', endPoint, requestAdd.data)
    return response.data
  },

  async deleteUserForAssignment({ commit, state, rootState }, requestDelete) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateUsersToCustomer_api_url}${requestDelete.params.customer}/relationships/users`
    const response = await webClientAuth.httpRequest('DELETE', endPoint, {
      data: requestDelete.data,
    })
    return response.data
  },
  async addCustomerForAssignment({ commit, state, rootState }, requestAdd) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateCustomersToUser_api_url}${requestAdd.params.user}/relationships/customers`
    const response = await webClientAuth.httpRequest('POST', endPoint, requestAdd.data)
    return response.data
  },

  async deleteCustomerForAssignment({ commit, state, rootState }, requestDelete) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateCustomersToUser_api_url}${requestDelete.params.user}/relationships/customers`
    const response = await webClientAuth.httpRequest('DELETE', endPoint, {
      data: requestDelete.data,
    })
    return response.data
  },

  /**
   * Update hospitals for users
   *
   * @param {*} { commit, state, rootState }
   *
   * @param {*} request
   * @returns
   */
  async updateCustomersToUser({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateCustomersToUser_api_url}${request.params.user}/relationships/customers`
    const response = await webClientAuth.httpRequest('POST', endPoint, request.data, {
      params: request.params,
    })
    return response.data
  },

  /**
   * Update impersonated user
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */

  async updateUserImpersonation({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.updateUserImpersonation_api_url}${request.params.user_id}`
    delete request.params
    const response = await webClientAuth.httpRequest('PATCH', endPoint, request.data)
    return response.data
  },
  /**
   * get user profile to fetch detail of impersonated user
   *
   * @param {*} { commit, state, rootState }
   * @param {*} request
   * @returns
   */
  async getUserProfile({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.getUserProfile_api_url}${request.params.user_id}`
    const response = await webClientAuth.httpRequest('GET', endPoint, request)
    return response.data
  },
  async createBenchmarkRequest({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.createNewBenchmark_api_url}`
    const response = await webClientAuth.httpRequest('POST', endPoint, request.data, {
      params: request.params,
    })
    return response.data
  },
  async createNewBenchmark({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.createNewBenchmark_api_url}`
    const response = await webClientAuth.httpRequest(
      'POST',
      endPoint,
      request.data
      // { params: request.params }
    )
    return response.data
  },
  async editBenchmark({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.createBenchmark_api_url}${request.params.user_id}`
    const response = await webClientAuth.httpRequest('PATCH', endPoint, request.data)
    return response.data
  },

  async updateCustomerProfileForAssociation({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${
      this._vm.$APP_CONFIG.updateCustomerProfile_api_url
    }${request.params.customerkey.toString()}`
    delete request.params.customerkey
    const response = await webClientAuth.httpRequest(
      'PATCH',
      endPoint,
      request.data
      // { params: request.params }
    )
    return response.data
  },
  async updateCustomerProfileForBenchmarkAssociation({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${
      this._vm.$APP_CONFIG.updateCustomerProfile_api_url
    }${request.params.customerkey.toString()}/relationships/benchmarks`
    delete request.params
    const response = await webClientAuth.httpRequest('PATCH', endPoint, request.data)
    return response.data
  },
  async deleteBenchmarkAPI({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.deleteBenchmark_api_url}${request.benchmarkName}`
    const response = await webClientAuth.httpRequest('DELETE', endPoint)
    return response.data
  },
  async editBenchmarkAPI({ commit, state, rootState }, request) {
    const endPoint = `${this._vm.$APP_CONFIG.api_base_url}${this._vm.$APP_CONFIG.editBenchmark_api_url}${request.params.benchmark_name}`
    delete request.params
    const response = await webClientAuth.httpRequest('PATCH', endPoint, request.data)
    return response.data
  },
}

import analyticsRoutes from '@plugins/trueVue-analytics/router/routes'
import analyticsModules from '@plugins/trueVue-analytics/state/modules'

// This is plugin object. It can be exported to be used anywhere.
const plugin = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, { router, i18n, store }) {
    analyticsModules.buildAndRegister(store)

    // add app specific routes to the existing router instance
    // inject the app specic state module to the existing store instance
    router.addRoutes(analyticsRoutes)
  },
}

export default plugin

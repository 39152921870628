var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WebDialog',{ref:"baseDialog",attrs:{"width":"900px","height":"450px"},scopedSlots:_vm._u([{key:"dialog-title",fn:function(){return [_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.title'))+" ")])]},proxy:true},{key:"dialog-content",fn:function(){return [_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-0 userHeaders",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.available_users'))+" ")])]),_c('v-col',{staticClass:"pr-0 userHeaders",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.assigned_users'))+" ")])])],1),_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"elevation-0 search",attrs:{"solo":"","data-test-id":"search-available-users","label":_vm.$t(
                'apps.trueVue_analytics.customers.dialog_add_user_to_customer.search_available_users'
              ),"flat":"","append-icon":"search","clearable":"","clear-icon":"cancel","hide-details":""},on:{"input":_vm.searchForUsersCheck},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"elevation-0 search",attrs:{"value":_vm.assignedUsersSearch,"solo":"","data-test-id":"search-assigned-users","label":_vm.$t(
                'apps.trueVue_analytics.customers.dialog_add_user_to_customer.search_assigned_users'
              ),"flat":"","append-icon":"search","clearable":"","clear-icon":"cancel","hide-details":""},on:{"input":function (v) { return (_vm.assignedUsersSearch = v); }}})],1)],1),_c('v-row',[_c('v-card',{staticClass:"userLists mx-auto",attrs:{"elevation":"0","outlined":""}},[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""}},[_vm._l((_vm.filteredItems),function(item,i){return [(!item)?_c('v-divider',{key:("divider-" + i)}):(!item.is_user_assigned_to_customer)?_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"active-class":"deep-blue--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.display)}})],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"add text-none",attrs:{"disabled":_vm.globalAdmin ? false : item.country_code !== _vm.currentCountryCode,"data-test-id":"add-button","input-value":active,"true-value":item,"rounded":"","elevation":"0"},on:{"click":function($event){return _vm.addUserToAssignedList(item)}}},[_vm._v(" Add "),_c('v-icon',{attrs:{"right":""}},[_vm._v("add")])],1)],1)]}}],null,true)}):_vm._e()]}),(_vm.filteredItems.length === 0)?_c('v-list-item',{staticClass:"justify-center"},[(!_vm.usersListLoading)?_c('label',{staticClass:"noAssignedData"},[_vm._v("No records found")]):_vm._e(),(_vm.usersListLoading)?_c('v-progress-circular',{attrs:{"width":3,"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUsersLoadMoreAvailable),expression:"isUsersLoadMoreAvailable"}]},[_c('v-btn',{attrs:{"data-test-id":"load-more-button","block":"","text":"","color":"primary","loading":_vm.usersListLoading},on:{"click":_vm.loadMoreUsers}},[_vm._v(_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.load_more'))+" ")])],1)],2)],1)],1),_c('v-card',{staticClass:"userLists mx-auto",attrs:{"elevation":"0","outlined":""}},[(_vm.changesBeingMade)?_c('div',{staticClass:"notSaved"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.not_yet_saved'))+" ")])]):_vm._e(),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""}},[_vm._l((_vm.selectedFilteredItems),function(item,i){return [(!item)?_c('v-divider',{key:("divider-" + i)}):(item.is_user_assigned_to_customer)?_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"active-class":"deep-blue--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.display)}})],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"remove text-none",attrs:{"disabled":_vm.globalAdmin ? false : item.country_code !== _vm.currentCountryCode,"input-value":active,"true-value":item,"data-test-id":"remove-button","rounded":"","elevation":"0"},on:{"click":function($event){return _vm.removeUserFromAssignedList(item)}}},[_vm._v(" Remove "),_c('v-icon',{attrs:{"right":""}},[_vm._v("close")])],1)],1)]}}],null,true)}):_vm._e()]}),(_vm.selectedFilteredItems.length === 0)?_c('v-list-item',{staticClass:"justify-center"},[(!_vm.inputAccountSearch)?_c('label',{staticClass:"noAssignedData"},[_vm._v(" "+_vm._s(_vm.$t( 'apps.trueVue_analytics.customers.dialog_add_user_to_customer.no_assigned_users' ))+" ")]):_vm._e(),(_vm.inputAccountSearch)?_c('label',{staticClass:"noAssignedData"},[_vm._v(" "+_vm._s(_vm.$t( 'apps.trueVue_analytics.customers.dialog_add_user_to_customer.no_records_found' ))+" ")]):_vm._e()]):_vm._e()],2)],1)],1)],1)]},proxy:true},{key:"confirm-button-text",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.customers.dialog_add_user_to_customer.button_save'))+" ")])]},proxy:true}])}),_c('WebDataLoading',{ref:"loader"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
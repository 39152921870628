<template>
  <WebAdvancedFilterSection class="advancedFilters">
    <!-- filter actions -->
    <v-row dense>
      <v-col>
        <v-row v-show="expandAdvancedFilters" no-gutters>
          <!-- advanced filters-->
          <v-col class="mx-2">
            <v-expand-transition>
              <v-row dense>
                <v-col>
                  <v-row align="center" dense>
                    <v-col class="filters" cols="auto">
                      <span>
                        {{ $t('apps.trueVue_analytics.users.advanced_filters.filters') }}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" lg="2" class="ml-2">
                      <v-row dense>
                        <v-col>
                          <v-select
                            v-model="selectedSearchFilters.customers"
                            :items="searchOptions.customers"
                            :label="
                              $t(
                                'apps.trueVue_analytics.users.advanced_filters.combobox_label.customers'
                              )
                            "
                            solo
                            flat
                            item-text="display"
                            item-value="value"
                            hide-details
                            @change="applyFilters"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="6" md="2" lg="2" class="ml-2">
                      <v-row dense>
                        <v-col>
                          <v-select
                            v-model="selectedSearchFilters.country"
                            :items="searchOptions.country"
                            :label="
                              $t(
                                'apps.trueVue_analytics.users.advanced_filters.combobox_label.country'
                              )
                            "
                            solo
                            flat
                            item-text="display"
                            item-value="value"
                            hide-details
                            @change="applyFilters"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="6" md="2" lg="2" class="ml-2">
                      <v-row dense>
                        <v-col>
                          <v-select
                            v-model="selectedSearchFilters.user_role"
                            :items="searchOptions.user_role"
                            :label="
                              $t(
                                'apps.trueVue_analytics.users.advanced_filters.combobox_label.user_role'
                              )
                            "
                            solo
                            flat
                            item-text="display"
                            item-value="value"
                            hide-details
                            @change="applyFilters"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-spacer />

                    <v-col cols="12" sm="6" md="4" lg="3">
                      <v-text-field
                        v-model.trim="selectedSearchFilters.searchByValue"
                        solo
                        class="elevation-0"
                        :label="
                          $t(
                            'apps.trueVue_analytics.users.advanced_filters.combobox_label.search_by_value'
                          )
                        "
                        flat
                        append-icon="search"
                        clearable
                        clear-icon="cancel"
                        hide-details
                        data-test-id="search-by-value-input"
                        @keydown.enter="applyFilters"
                        @click:append="applyFilters"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-col>
        </v-row>

        <v-row v-if="showFilterChips" dense>
          <!-- selected filters in chips format -->
          <v-col>
            <template v-for="(item, index) in filterChips">
              <v-chip
                v-if="item"
                :key="index"
                label
                color="primary"
                dark
                close
                close-icon="clear"
                class="ma-2"
                @click:close="removeFilter(item)"
              >
                <span>{{ item.subDisplay }}:</span>&nbsp;
                <strong>{{ item.display }}</strong>
              </v-chip>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </WebAdvancedFilterSection>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'UsersAdvancedFilters',
  mixins: [trueVueMgmtStoreHelper],
  data: () => ({
    showFilterChips: false,
    searchOptionsPositionMap: {
      searchBy: 0,
      country: 1,
      customers: 2,
      user_role: 3,
    },
    searchOptions: {
      searchBy: [],
      country: [],
      customers: [],
      user_role: [],
    },
    selectedSearchFilters: {},
    filterChips: [],
    expandAdvancedFilters: true,
    globalAdmin: localStorage.getItem('globaladmin'),
    limit: 3000,
  }),
  mounted() {
    this.$nextTick(async function awaitLoadFilters() {
      await this.loadFilters()
    })
  },
  created() {
    this.valueLableMap = {
      country: this.$t('apps.trueVue_analytics.users.advanced_filters.columns.country'),
      user_role: this.$t('apps.trueVue_analytics.users.advanced_filters.columns.user_role'),
      customers: this.$t('apps.trueVue_analytics.users.advanced_filters.columns.customers'),
    }
    this.selectedSearchFilters = this.defaultSelectedSearchFilters()
  },
  methods: {
    async loadFilters() {
      try {
        this.countries.sort((a, b) => a.value.localeCompare(b.value))
        this.countries.forEach((item) => {
          this.searchOptions.country.push({
            display: item.value,
            value: item.id,
          })
        })

        this.user_role.sort((a, b) => a.value.localeCompare(b.value))
        this.user_role.forEach((item) => {
          this.searchOptions.user_role.push({
            display: item.value,
            value: item.id,
          })
        })
        const request = {
          params: {
            country: JSON.stringify([]),
            users: JSON.stringify([]),
            user_role: JSON.stringify([]),
            _limit: this.limit,
            _orderBy: 'name',
            _page: 1,
            _ascending: true,
          },
        }

        const response = await this.getCustomers(request)
        if (response) {
          response.data.map((items) => {
            this.searchOptions.customers.push({
              display: items.name,
              value: items.id,
            })
          })
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    syncFilters() {
      const dataFilters = {}

      if (
        this.selectedSearchFilters.searchByValue !== '' &&
        this.selectedSearchFilters.searchByValue != null
      ) {
        dataFilters.search_term = this.selectedSearchFilters.searchByValue.replace(/^\s+|\s+$/g, '')
      }
      for (const prop in this.selectedSearchFilters) {
        if (this.selectedSearchFilters[prop] !== '' && this.selectedSearchFilters[prop] != null) {
          if (prop !== 'searchBy' && prop !== 'searchByValue') {
            dataFilters[prop] = [this.selectedSearchFilters[prop]]
          }
        }
      }
      this.$emit('update:selectedDataFilters', dataFilters)
    },
    removeFilter(item) {
      if (
        Object.prototype.hasOwnProperty.call(this.selectedSearchFilters, item.column) &&
        item.column !== item.value
      ) {
        this.selectedSearchFilters[item.column] = ''
      } else {
        this.selectedSearchFilters.searchBy = ''
        this.selectedSearchFilters.searchByValue = ''
      }

      this.applyFilters()
    },
    defaultSelectedSearchFilters() {
      return {
        searchBy: '',
        searchByValue: '',
        country: '',
        user_role: '',
        customers: '',
      }
    },
    applyFilters() {
      this.showFilterChips = true

      this.filterChips = []

      if (
        this.selectedSearchFilters.searchByValue !== '' &&
        this.selectedSearchFilters.searchByValue !== null
      ) {
        const result = this.searchOptions.searchBy.filter(
          (item) => item.value === this.selectedSearchFilters.searchBy.replace(/^\s+|\s+$/g, '')
        )
        const searchValue = this.selectedSearchFilters.searchByValue.replace(/^\s+|\s+$/g, '')
        this.filterChips[this.searchOptionsPositionMap.searchBy] = {
          column: searchValue,
          value: searchValue,
          subDisplay: 'search',
          display: searchValue,
        }
      }

      for (const prop in this.selectedSearchFilters) {
        if (this.selectedSearchFilters[prop] !== '') {
          if (prop !== 'searchBy' && prop !== 'searchByValue') {
            const value = this.selectedSearchFilters[prop]

            if (value != null) {
              const result = this.searchOptions[prop].filter((item) => item.value === value)

              this.filterChips[this.searchOptionsPositionMap[prop]] = {
                column: prop,
                value,
                display: result[0].display,
                subDisplay: this.valueLableMap[prop],
              }
            }
          }
        }
      }
      this.syncFilters()
    },
  },
}
</script>,

<style lang="scss" scoped>
.filters {
  color: #001a72;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

::v-deep .theme--light.v-label {
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
}

::v-deep .theme--light.v-icon {
  width: 22px;
  color: gray !important;
}
.advancedFilters {
  border-top: 1px solid rgb(208, 208, 208);
  background-color: #ffffff !important;
}
::v-deep .v-chip--label {
  border-radius: 15px !important;
}

::v-deep .v-application .primary {
  background-color: #e0e0e0 !important;
}

::v-deep .theme--dark.v-chip {
  background: #e0e0e0 !important;
}

.v-chip.v-size--default {
  height: 24px;
}
::v-deep span.v-chip__content {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .v-list-item__title {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

::v-deep .v-select__selection {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

::v-deep input {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}
</style>,

<style type="text/css">
/* .v-menu__content{
  margin-top: 48px !important;
} */
</style>,

import 'babel-polyfill'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import { application, bootstrap } from '@baxter/web-vueapp-lib'
import navigations from '@plugins/navigations'
import pluginInstaller from '@plugins/pluginInstaller'
import vuetify from '@plugins/vuetify'

bootstrap(Vue, navigations)
  .then(({ store, router, i18n }) => {
    pluginInstaller({ store, router, i18n })

    /** Create the Vue Instance and setup the router, store, etc.. */
    new Vue({
      vuetify,
      router,
      store,
      i18n,
      beforeCreate() {
        this.$store.commit('initialiseStore')
      },
      render: (h) => h(application),
    }).$mount('#app')
  })
  .catch((error) => {
    console.log('Bootstraping vuejs app failed')
    console.log(error)
  })

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WebAdvancedFilterSection',{staticClass:"advancedFilters"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandAdvancedFilters),expression:"expandAdvancedFilters"}],attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-2"},[_c('v-expand-transition',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"filters",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('apps.trueVue_analytics.users.advanced_filters.filters'))+" ")])]),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","sm":"6","md":"2","lg":"2"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.searchOptions.customers,"label":_vm.$t(
                              'apps.trueVue_analytics.users.advanced_filters.combobox_label.customers'
                            ),"solo":"","flat":"","item-text":"display","item-value":"value","hide-details":""},on:{"change":_vm.applyFilters},model:{value:(_vm.selectedSearchFilters.customers),callback:function ($$v) {_vm.$set(_vm.selectedSearchFilters, "customers", $$v)},expression:"selectedSearchFilters.customers"}})],1)],1)],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","sm":"6","md":"2","lg":"2"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.searchOptions.country,"label":_vm.$t(
                              'apps.trueVue_analytics.users.advanced_filters.combobox_label.country'
                            ),"solo":"","flat":"","item-text":"display","item-value":"value","hide-details":""},on:{"change":_vm.applyFilters},model:{value:(_vm.selectedSearchFilters.country),callback:function ($$v) {_vm.$set(_vm.selectedSearchFilters, "country", $$v)},expression:"selectedSearchFilters.country"}})],1)],1)],1),_c('v-col',{staticClass:"ml-2",attrs:{"cols":"12","sm":"6","md":"2","lg":"2"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.searchOptions.user_role,"label":_vm.$t(
                              'apps.trueVue_analytics.users.advanced_filters.combobox_label.user_role'
                            ),"solo":"","flat":"","item-text":"display","item-value":"value","hide-details":""},on:{"change":_vm.applyFilters},model:{value:(_vm.selectedSearchFilters.user_role),callback:function ($$v) {_vm.$set(_vm.selectedSearchFilters, "user_role", $$v)},expression:"selectedSearchFilters.user_role"}})],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"solo":"","label":_vm.$t(
                          'apps.trueVue_analytics.users.advanced_filters.combobox_label.search_by_value'
                        ),"flat":"","append-icon":"search","clearable":"","clear-icon":"cancel","hide-details":"","data-test-id":"search-by-value-input"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilters.apply(null, arguments)},"click:append":_vm.applyFilters},model:{value:(_vm.selectedSearchFilters.searchByValue),callback:function ($$v) {_vm.$set(_vm.selectedSearchFilters, "searchByValue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"selectedSearchFilters.searchByValue"}})],1)],1)],1)],1)],1)],1)],1),(_vm.showFilterChips)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_vm._l((_vm.filterChips),function(item,index){return [(item)?_c('v-chip',{key:index,staticClass:"ma-2",attrs:{"label":"","color":"primary","dark":"","close":"","close-icon":"clear"},on:{"click:close":function($event){return _vm.removeFilter(item)}}},[_c('span',[_vm._v(_vm._s(item.subDisplay)+":")]),_vm._v("  "),_c('strong',[_vm._v(_vm._s(item.display))])]):_vm._e()]})],2)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
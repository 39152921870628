<template>
  <div>
    <WebDialog ref="baseDialog" width="770px" height="420px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.benchmark.delete_benchmark_confirmation.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="12" lg="12" class="px-0 messageSection">
            <div class="message">
              <span class="multiline-text">
                {{
                  $t(
                    'apps.trueVue_analytics.benchmark.delete_benchmark_confirmation.delete_benchmark_message'
                  )
                }}
              </span>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span class="text-none">
          {{ $t('apps.trueVue_analytics.benchmark.delete_benchmark_confirmation.delete_button') }}
        </span>
      </template>
    </WebDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'DeleteBenchmarkConfirmation',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    benchmarkDetails: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    selectedBenchmark: [],
  }),
  methods: {
    async open() {
      this.$refs.loader.show()
      this.selectedBenchmark = this.benchmarkDetails
      this.$refs.loader.hide()

      const result = await this.$refs.baseDialog.show()

      if (result === true) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      }
      this.$refs.loader.hide()
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  .v-input__control {
    .v-input__slot {
      margin-bottom: 0px !important;
    }
  }
}

.search {
  max-width: 300px;
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}
::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

v.divider {
  border: none;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 700px;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.messageSection {
  padding: 0 12px;
  .message {
    color: #54585a;
    font-family: 'Noto Sans';
    font-size: 12px;
    line-height: 19px;
  }
}

.noAccountsMessage {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
  padding-left: 10px;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}
.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: auto;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}

span.multiline-text {
  white-space: pre-line;
}
</style>,

<template>
  <div>
    <WebAdvancedFilterSection class="advancedFilters">
      <!-- filter actions -->
      <v-row dense>
        <v-col>
          <v-row v-show="expandAdvancedFilters" no-gutters>
            <!-- advanced filters-->
            <v-col class="mx-2">
              <v-expand-transition>
                <v-row dense>
                  <v-col>
                    <v-row align="center" dense>
                      <v-col class="filters" cols="auto">
                        <span>
                          {{ $t('apps.trueVue_analytics.benchmark.advanced_filters.filters') }}
                        </span>
                      </v-col>

                      <v-col cols="12" sm="6" md="2" lg="2" class="ml-2 applyFilters">
                        <v-row dense>
                          <v-col>
                            <v-select
                              v-model="selectedSearchFilters.country"
                              :items="searchOptions.country"
                              :label="
                                $t(
                                  'apps.trueVue_analytics.benchmark.advanced_filters.combobox_label.country'
                                )
                              "
                              solo
                              flat
                              item-text="display"
                              item-value="value"
                              hide-details
                              @change="applyFilters"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-spacer />

                      <v-col cols="12" sm="6" md="4" lg="3">
                        <v-text-field
                          v-model.trim="selectedSearchFilters.searchByValue"
                          solo
                          class="elevation-0 search"
                          :label="
                            $t(
                              'apps.trueVue_analytics.benchmark.advanced_filters.combobox_label.search_by_value'
                            )
                          "
                          flat
                          append-icon="search"
                          clearable
                          clear-icon="cancel"
                          hide-details
                          data-test-id="search-by-value-input"
                          @keydown.enter="applyFilters"
                          @click:append="applyFilters"
                        ></v-text-field>
                      </v-col>
                      <EditBenchmark
                        ref="createBenchmark"
                        :is-edit-dialog="isEditDialog"
                      ></EditBenchmark>
                      <v-btn
                        data-test-id="create-benchmark-button"
                        class="createButton text-none"
                        rounded
                        color="primary"
                        dark
                        @click="createBenchmark"
                      >
                        {{
                          $t(
                            'apps.trueVue_analytics.benchmark.create_benchmark.create_benchmark_button'
                          )
                        }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>

          <v-row v-if="showFilterChips" dense>
            <!-- selected filters in chips format -->
            <v-col>
              <template v-for="(item, index) in filterChips">
                <v-chip
                  v-if="item"
                  :key="index"
                  label
                  color="primary"
                  dark
                  close
                  close-icon="clear"
                  class="ma-2"
                  @click:close="removeFilter(item)"
                >
                  <span>{{ item.subDisplay }}:</span>&nbsp;
                  <strong>{{ item.display }}</strong>
                </v-chip>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </WebAdvancedFilterSection>
    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

const EditBenchmark = () => import('@plugins/trueVue-analytics/components/editBenchmark')

export default {
  name: 'BenchmarkAdvancedFilters',
  components: {
    EditBenchmark,
  },
  mixins: [trueVueMgmtStoreHelper],
  data: () => ({
    showFilterChips: false,
    searchOptionsPositionMap: {
      searchBy: 0,
      country: 1,
    },
    searchOptions: {
      searchBy: [],
      country: [],
    },
    selectedSearchFilters: {},
    filterChips: [],
    expandAdvancedFilters: true,
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
    user_id: localStorage.getItem('user_id'),
    isEditDialog: false,
    // benchmarkData: [],
  }),
  mounted() {
    this.$nextTick(async function awaitLoadFilters() {
      await this.loadFilters()
    })
  },
  created() {
    this.valueLableMap = {
      country: this.$t('apps.trueVue_analytics.benchmark.advanced_filters.columns.country'),
    }
    this.selectedSearchFilters = this.defaultSelectedSearchFilters()
  },
  methods: {
    loadFilters() {
      this.benchmarkCountries.forEach((item) => {
        this.searchOptions.country.push({
          display: item.value,
          value: item.id,
        })
      })
    },
    syncFilters() {
      const dataFilters = {}

      if (
        this.selectedSearchFilters.searchByValue !== '' &&
        this.selectedSearchFilters.searchByValue != null
      ) {
        dataFilters.search_term = this.selectedSearchFilters.searchByValue.replace(/^\s+|\s+$/g, '')
      }

      for (const prop in this.selectedSearchFilters) {
        if (this.selectedSearchFilters[prop] !== '' && this.selectedSearchFilters[prop] != null) {
          if (prop !== 'searchBy' && prop !== 'searchByValue') {
            dataFilters[prop] = [this.selectedSearchFilters[prop]]
          }
        }
      }
      this.$emit('update:selectedDataFilters', dataFilters)
    },
    removeFilter(item) {
      if (Object.prototype.hasOwnProperty.call(this.selectedSearchFilters, item.column)) {
        this.selectedSearchFilters[item.column] = ''
      } else {
        this.selectedSearchFilters.searchBy = ''
        this.selectedSearchFilters.searchByValue = ''
      }

      this.applyFilters()
    },
    defaultSelectedSearchFilters() {
      return {
        searchBy: '',
        searchByValue: '',
        country: '',
      }
    },
    applyFilters() {
      this.showFilterChips = true
      this.filterChips = []

      if (
        this.selectedSearchFilters.searchByValue !== '' &&
        this.selectedSearchFilters.searchByValue !== null
      ) {
        const result = this.searchOptions.searchBy.filter(
          (item) => item.value === this.selectedSearchFilters.searchBy.replace(/^\s+|\s+$/g, '')
        )
        const searchValue = this.selectedSearchFilters.searchByValue.replace(/^\s+|\s+$/g, '')
        this.filterChips[this.searchOptionsPositionMap.searchBy] = {
          column: searchValue,
          value: searchValue,
          subDisplay: 'search',
          display: searchValue,
        }
      }

      for (const prop in this.selectedSearchFilters) {
        if (this.selectedSearchFilters[prop] !== '') {
          if (prop !== 'searchBy' && prop !== 'searchByValue') {
            const value = this.selectedSearchFilters[prop]

            if (value != null) {
              const result = this.searchOptions[prop].filter((item) => item.value === value)

              this.filterChips[this.searchOptionsPositionMap[prop]] = {
                column: prop,
                value,
                display: result[0].display,
                subDisplay: this.valueLableMap[prop],
              }
            }
          }
        }
      }
      this.syncFilters()
    },
    async createBenchmark() {
      try {
        const result = await this.$refs.createBenchmark.open()
        if (result !== false) {
          this.$refs.loader.show()
          const benchmarkName = result.benchmark_name
          const customersArray = []
          result.customers.map((item) => {
            customersArray.push(item.value)
          })
          const request = {
            data: {
              entry_set: {
                benchmark_name: result.benchmark_name,
                legend_abbreviation: result.legend_abbreviation,
                customers: customersArray,
                benchmark_type: result.benchmark_type,
                benchmark_country_code: result.benchmark_country_code,
              },
            },
            params: {
              user_id: this.user_id,
            },
          }
          const response = await this.createBenchmarkRequest(request)
          if (response) {
            this.$emit('update:newBenchmarkCreated', response)
            this.$refs.loader.hide()
            this.$publishAlert(
              benchmarkName +
                this.$t('apps.trueVue_analytics.benchmark.messages.benchmark_created_success'),
              'success'
            )
          }
        }
      } catch (exception) {
        this.$refs.loader.hide()
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
  },
}
</script>,

<style lang="scss" scoped>
.filters {
  color: #001a72;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}

::v-deep .theme--light.v-label {
  color: #54585a;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 19px;
}

::v-deep .theme--light.v-icon {
  width: 22px;
  color: gray !important;
}
.advancedFilters {
  border-top: 1px solid rgb(208, 208, 208);
  background-color: #ffffff !important;
  .search {
    padding-right: 30px;
    text-align: -webkit-right;
  }
}
::v-deep .v-chip--label {
  border-radius: 15px !important;
}

::v-deep .v-application .primary {
  background-color: #e0e0e0 !important;
}

::v-deep .theme--dark.v-chip {
  background: #e0e0e0 !important;
}

.v-chip.v-size--default {
  height: 24px;
}
::v-deep span.v-chip__content {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .v-list-item__title {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

::v-deep .v-select__selection {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

::v-deep input {
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}

button.createButton {
  height: 49px;
  width: 198px;
  border-radius: 30px;
}
</style>,

<style type="text/css">
/* .v-menu__content{
  margin-top: 48px !important;
} */
</style>,

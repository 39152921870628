import { render, staticRenderFns } from "./benchmark.vue?vue&type=template&id=5d138a1e&scoped=true&"
import script from "./benchmark.vue?vue&type=script&lang=js&"
export * from "./benchmark.vue?vue&type=script&lang=js&"
import style0 from "./benchmark.vue?vue&type=style&index=0&id=5d138a1e&lang=scss&scoped=true&"
import style1 from "./benchmark.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-6c7283b961/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d138a1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../.yarn/__virtual__/vuetify-loader-virtual-75fc87f20c/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VCol,VExpandTransition,VRow,VSelect,VSpacer,VTextField})

<template>
  <div>
    <WebDialog ref="baseDialog" width="900px" height="450px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto mt-4">
          <v-col cols="12" sm="12" md="6" lg="6" class="header pl-0">
            <h4>
              {{
                $t(
                  'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.available_benchmarks'
                )
              }}
            </h4>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" class="header pr-0">
            <h4>
              {{
                $t(
                  'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.current_benchmark'
                )
              }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0">
            <v-text-field
              v-model="searchQuery"
              solo
              class="elevation-0 search"
              data-test-id="search-available-benchmarks"
              :label="$t('apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.search')"
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="searchForBenchmarksCheck"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0">
            <div class="message">
              <span>
                {{
                  $t(
                    'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.current_benchmark_message1'
                  )
                }}
              </span>
              <br />
              <span>
                {{
                  $t(
                    'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.current_benchmark_message2'
                  )
                }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-card class="benchmarkLists mx-auto" elevation="0" outlined>
            <v-list>
              <v-list-item-group multiple>
                <template v-for="(item, i) in filteredItems" v-show="!item.is_benchmark_assigned">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="!item.is_benchmark_assigned"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          class="replace text-none"
                          data-test-id="replace-button"
                          :input-value="active"
                          :true-value="item"
                          rounded
                          elevation="0"
                          @click="replaceBenchmark(item)"
                        >
                          Replace
                          <v-icon right>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="filteredItems.length === 0" class="justify-center">
                  <label v-if="!benchmarksListLoading" class="noAssignedData"
                    >No records found</label
                  >
                  <v-progress-circular
                    v-if="benchmarksListLoading"
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-list-item>
                <v-list-item v-show="isBenchmarksLoadMoreAvailable">
                  <v-btn
                    data-test-id="load-more-button"
                    block
                    text
                    color="primary"
                    :loading="benchmarksListLoading"
                    @click="loadMoreBenchmarks"
                    >{{
                      $t(
                        'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.load_more'
                      )
                    }}</v-btn
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card class="benchmarkLists mx-auto" elevation="0" outlined>
            <div v-if="changesBeingMade" class="notSaved">
              <span>
                {{
                  $t(
                    'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.not_yet_saved'
                  )
                }}
              </span>
            </div>

            <v-list>
              <v-list-item-group multiple>
                <template v-for="(item, i) in currentBenchmark.benchmark">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="item.is_benchmark_assigned"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          class="remove text-none"
                          :input-value="active"
                          :true-value="item"
                          data-test-id="remove-button"
                          rounded
                          elevation="0"
                          @click="removeBenchmarkFromCurrentList(item)"
                        >
                          Remove
                          <v-icon right>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="currentBenchmark.benchmark.length === 0" class="justify-center">
                  <label class="noAssignedData">
                    {{
                      $t(
                        'apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.no_assigned_benchmarks'
                      )
                    }}
                  </label>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span>
          {{ $t('apps.trueVue_analytics.customers.dialog_edit_assigned_benchmark.button_save') }}
        </span>
      </template>
    </WebDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'EditAssignedBenchmark',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    customerDetails: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    searchOptions: {
      benchmarks: [],
    },
    items: [],
    selected: [],
    limit: 20,
    availableBenchmarkSearch: '',
    currentBenchmark: { benchmark: [] },
    userId: localStorage.getItem('user_id'),
    changesBeingMade: false,
    selectedAccounts: [],
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    currentCountryCode: localStorage.getItem('country'),
    adminCountry: localStorage.getItem('country'),
    benchmarksPage: 2,
    benchmarksListLoading: false,
    isBenchmarksLoadMoreAvailable: false,
    searchQuery: '',
    searchTimeout: null,
  }),
  computed: {
    filteredItems() {
      return this._.orderBy(
        this.searchOptions.benchmarks.filter((item) => {
          if (!this.availableBenchmarkSearch && this.availableBenchmarkSearch === '') {
            return this.searchOptions.benchmarks
          }
          return item.display
            ? item.display.toLowerCase().includes(this.availableBenchmarkSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
  },
  methods: {
    async open() {
      this.$refs.loader.show()
      await this.loadFilters()
      this.$refs.loader.hide()
      const result = await this.$refs.baseDialog.show()
      if (result === true) {
        if (this.currentBenchmark.benchmark.length >= 0 && this.selectedAccounts != null) {
          return new Promise((resolve, reject) => {
            resolve(this.currentBenchmark.benchmark)
          })
        }
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
      this.$refs.loader.hide()

      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
    async loadFilters() {
      try {
        this.searchQuery = ''
        this.benchmarksPage = 2
        this.selectedAccounts = this.customerDetails
        this.currentBenchmark.benchmark = []
        this.searchOptions.benchmarks = []
        const request = {
          params: {
            user_id: this.userId,
            country: JSON.stringify([]),
            customers: JSON.stringify([]),
            _limit: this.limit,
            _orderBy: 'benchmark_name',
            _page: 1,
            _ascending: true,
          },
        }

        if (this.selectedAccounts.benchmarks[0]) {
          this.currentBenchmark.benchmark.push({
            display: this.selectedAccounts.benchmarks[0],
            is_benchmark_assigned: true,
          })
        }

        const response = await this.getBenchmarks(request)
        this.benchmarkData = response.data

        if (response.total_pages > 1) {
          this.isBenchmarksLoadMoreAvailable = true
        }
        if (response) {
          response.data.forEach((items) => {
            if (items.benchmark_name === this.selectedAccounts.benchmarks[0]) {
              items.is_benchmark_assigned = true
            }

            this.searchOptions.benchmarks.push({
              display: items.benchmark_name,
              value: items.legend_abbreviation,
              is_benchmark_assigned: items.is_benchmark_assigned
                ? items.is_benchmark_assigned
                : false,
              country_of_creation: items.country_of_creation,
              benchmark_type: items.benchmark_type,
            })
          })
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async loadMoreBenchmarks() {
      this.benchmarksListLoading = true
      const request = {
        params: {
          user_id: this.userId,
          country: JSON.stringify([]),
          customers: JSON.stringify([]),
          _limit: this.limit,
          _orderBy: 'benchmark_name',
          _page: this.benchmarksPage,
          _ascending: true,
          search_term: this.searchQuery,
        },
      }
      try {
        const response = await this.getBenchmarks(request)
        if (response) {
          this.benchmarkData = response.data
          response.data.forEach((items) => {
            if (items.benchmark_name === this.selectedAccounts.benchmarks[0]) {
              items.is_benchmark_assigned = true
            }

            this.searchOptions.benchmarks.push({
              display: items.benchmark_name,
              value: items.legend_abbreviation,
              is_benchmark_assigned: items.is_benchmark_assigned
                ? items.is_benchmark_assigned
                : false,
              country_of_creation: items.country_of_creation,
              benchmark_type: items.benchmark_type,
            })
          })

          if (this.benchmarksPage === response.total_pages) {
            this.isBenchmarksLoadMoreAvailable = false
          }
          this.benchmarksPage += 1
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.benchmarksListLoading = false
    },
    async searchForBenchmarksCheck() {
      const value = this.searchQuery
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchForBenchmarks(value)
      }, 500)
    },
    async searchForBenchmarks(value) {
      try {
        this.isBenchmarksLoadMoreAvailable = false
        this.benchmarksPage = 2
        this.selectedAccounts = this.customerDetails
        this.searchOptions.benchmarks = []
        this.benchmarksListLoading = true
        const request = {
          params: {
            user_id: this.userId,
            country: JSON.stringify([]),
            customers: JSON.stringify([]),
            _limit: value ? 3000 : this.limit,
            _orderBy: 'benchmark_name',
            _page: 1,
            _ascending: true,
            search_term: value,
          },
        }

        const response = await this.getBenchmarks(request)

        if (this.searchQuery !== value) {
          return
        }

        if (response.total_pages > 1) {
          this.isBenchmarksLoadMoreAvailable = true
        }
        if (response) {
          response.data.forEach((items) => {
            if (items.benchmark_name === this.selectedAccounts.benchmarks[0]) {
              items.is_benchmark_assigned = true
            }

            if (items.benchmark_name.toLowerCase().includes(value.toLowerCase())) {
              this.searchOptions.benchmarks.push({
                display: items.benchmark_name,
                value: items.legend_abbreviation,
                is_benchmark_assigned: items.is_benchmark_assigned
                  ? items.is_benchmark_assigned
                  : false,
                country_of_creation: items.country_of_creation,
                benchmark_type: items.benchmark_type,
              })
            } else if (value === null) {
              this.searchOptions.benchmarks.push({
                display: items.benchmark_name,
                value: items.legend_abbreviation,
                is_benchmark_assigned: items.is_benchmark_assigned
                  ? items.is_benchmark_assigned
                  : false,
                country_of_creation: items.country_of_creation,
                benchmark_type: items.benchmark_type,
              })
            }
          })
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.benchmarksListLoading = false
    },
    replaceBenchmark(selectedItem) {
      const currentBenchmark = this.currentBenchmark.benchmark

      if (!currentBenchmark.includes(selectedItem)) {
        this.currentBenchmark.benchmark = []
        this.currentBenchmark.benchmark.push({
          display: selectedItem.display,
          value: selectedItem.value,
          is_benchmark_assigned: true,
        })
        this.searchOptions.benchmarks.map((items) => {
          if (items.display === selectedItem.display) {
            items.is_benchmark_assigned = true
          } else {
            items.is_benchmark_assigned = false
          }
        })
        this.changesBeingMade = true
      }
    },
    removeBenchmarkFromCurrentList(selectedItem) {
      const removedBenchmark = this.currentBenchmark.benchmark
      this.currentBenchmark.benchmark = this.currentBenchmark.benchmark.filter(
        (item) => item.value !== selectedItem.value
      )
      this.searchOptions.benchmarks.map((items) => {
        if (items.value === selectedItem.value) {
          items.is_benchmark_assigned = false
        }
      })
      this.changesBeingMade = true
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    .v-text-field__slot {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}

.search {
  max-width: 300px;
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}
::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

v.divider {
  border: none;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 700px;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.benchmarkLists {
  width: 403px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
  .v-list {
    padding-top: 0;
    .v-list-item {
      min-height: 43px;
      padding: 0 9px;
      .v-list-item__content {
        .v-list-item__title {
          color: #54585a;
          font-family: 'Noto Sans';
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

button.replace {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 84px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

button.remove {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 87px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

.notSaved {
  background-color: #eff0f0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 9px;
  line-height: 19px;
  padding-left: 4px;
  color: #54585a;
}
.message {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 12px;
  line-height: 19px;
}

.header {
  padding: 0 12px;
}
.noAssignedData {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}
.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: 110px;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}
</style>,

<template>
  <div>
    <WebDialog ref="baseDialog" :disable-confirm="!disableConfirm" width="900px" height="571px">
      <template v-slot:dialog-title>
        <span v-if="isEditDialog" class="text-h6">{{
          $t('apps.trueVue_analytics.benchmark.edit_benchmark.title')
        }}</span>
        <span v-if="!isEditDialog" class="text-h6">
          {{ $t('apps.trueVue_analytics.benchmark.create_benchmark.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="12" lg="12" class="px-0 pt-0">
            <div class="message">
              <span>
                {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.edit_benchmark_message') }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto mt-3">
          <v-col cols="12" sm="12" md="6" lg="6" class="header pl-0">
            <div class="name">
              <h4>
                {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.benchmark_name') }}
              </h4>
              <div class="errorMessage">
                <span v-if="nameExists">
                  {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.benchmark_name_error') }}
                </span>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" lg="6" class="header pr-0">
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.benchmark_abbreviation') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0 pb-0">
            <v-text-field
              ref="benchmarkNameInput"
              class="benchmarkName"
              :value="editBenchmarkName"
              :label="benchmarkNameLabel"
              required
              :rules="nameRules"
              :maxlength="32"
              :counter="32"
              single-line
              flat
              solo
              @change="(v) => (editBenchmarkName = v)"
              @input="(v) => editBenchmarkNameExistence(v)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0 pb-0">
            <v-text-field
              ref="benchmarkAbbrvInput"
              class="benchmarkAbbrv"
              :value="editBenchmarkAbbrv"
              :label="benchmarkAbbrvLabel"
              single-line
              :rules="abbrvRules"
              :counter="3"
              :maxlength="3"
              required
              flat
              solo
              @change="(v) => (editBenchmarkAbbrv = v)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="header pl-0">
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.country') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0" :class="{ 'pb-0': !globalAdmin }">
            <v-select
              v-if="globalAdmin"
              v-model="benchmarkCountry"
              class="selectCountry"
              :items="benchmarkCountrySelect.country"
              solo
              :label="countryLabel"
              flat
              item-text="display"
              item-value="value"
              hide-details
            ></v-select>
            <v-text-field
              v-if="!globalAdmin"
              class="selectCountry"
              :value="benchmarkCountry"
              :label="benchmarkCountrySelect.country.display"
              required
              single-line
              readonly
              flat
              solo
              item-text="benchmarkCountrySelect.country.display"
              item-value="benchmarkCountrySelect.country.value"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="accountsHeader pl-0"
            :class="{ 'pt-0': !globalAdmin }"
          >
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.available_customers') }}
            </h4>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="accountsHeader pr-0"
            :class="{ 'pt-0': !globalAdmin }"
          >
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.current_customers') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pl-0">
            <v-text-field
              v-model="searchQuery"
              data-test-id="search-available-customers"
              solo
              class="elevation-0 search availableAccSearch"
              :label="$t('apps.trueVue_analytics.benchmark.edit_benchmark.search')"
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="searchForCustomersCheck"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" class="pr-0">
            <v-text-field
              :value="inputAccountSearch"
              solo
              class="elevation-0 search inputAccountSearch"
              :label="$t('apps.trueVue_analytics.benchmark.edit_benchmark.search')"
              flat
              append-icon="search"
              clearable
              clear-icon="cancel"
              hide-details
              @input="(v) => (inputAccountSearch = v)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card class="customersLists mx-auto" elevation="0" outlined>
            <v-list>
              <v-list-item-group multiple>
                <template
                  v-for="(item, i) in filteredItems"
                  v-show="!item.is_customer_assigned_to_benchmark"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="!item.is_customer_assigned_to_benchmark"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== adminCountry"
                          class="add text-none"
                          :input-value="active"
                          :true-value="item"
                          rounded
                          elevation="0"
                          @click="addCustomerToBenchmark(item)"
                        >
                          Add
                          <v-icon right>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="filteredItems.length === 0" class="justify-center">
                  <label v-if="!customersListLoading" class="noAssignedData"
                    >No records found</label
                  >
                  <v-progress-circular
                    v-if="customersListLoading"
                    :width="3"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-list-item>
                <v-list-item v-show="isCustomersLoadMoreAvailable">
                  <v-btn
                    data-test-id="load-more-button"
                    block
                    text
                    color="primary"
                    :loading="customersListLoading"
                    @click="loadMoreCustomers"
                  >
                    {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.load_more') }}
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card class="customersLists mx-auto" elevation="0" outlined>
            <div v-if="changesBeingMade" class="notSaved">
              <span>
                {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.not_yet_saved') }}
              </span>
            </div>

            <v-list>
              <v-list-item-group multiple>
                <template
                  v-for="(item, i) in selectedFilteredItems"
                  v-show="item.is_customer_assigned_to_benchmark"
                >
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else-if="item.is_customer_assigned_to_benchmark"
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-blue--text text--accent-4"
                  >
                    <template v-slot="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.display"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          :disabled="globalAdmin ? false : item.country_code !== adminCountry"
                          class="remove text-none"
                          :input-value="active"
                          :true-value="item"
                          rounded
                          elevation="0"
                          @click="removeCustomerFromBenchmark(item)"
                        >
                          Remove
                          <v-icon right>close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                <v-list-item v-if="selectedFilteredItems.length === 0" class="justify-center">
                  <label v-if="!inputAccountSearch" class="noAssignedData">
                    {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.no_assigned_accounts') }}
                  </label>
                  <label v-if="inputAccountSearch" class="noAssignedData">
                    {{
                      $t(
                        'apps.trueVue_analytics.benchmark.edit_benchmark.no_assigned_accounts_search'
                      )
                    }}
                  </label>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span v-if="isEditDialog">
          {{ $t('apps.trueVue_analytics.benchmark.edit_benchmark.button_save') }}
        </span>
        <span v-if="!isEditDialog">
          {{ $t('apps.trueVue_analytics.benchmark.create_benchmark.button_save') }}
        </span>
      </template>
    </WebDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'EditBenchmark',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    benchmarkDetails: {
      type: Object,
      required: false,
    },
    isEditDialog: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    searchOptions: {
      customers: [],
    },
    items: [],
    selected: [],
    search: '',
    availableAccSearch: '',
    inputAccountSearch: '',
    searchItem: [],
    selectedSearchFilters: { customers: [] },
    userId: localStorage.getItem('user_id'),
    changesBeingMade: false,
    benchmarkCountrySelect: {
      country: [],
    },
    selectedBenchmark: [],
    limit: 100,
    editBenchmarkName: null,
    editBenchmarkAbbrv: null,
    editBenchmarkNameOriginal: false,
    countryLabel: '',
    benchmarkCountry: null,
    listOfBenchmarks: [],
    nameExists: false,
    nameRules: [
      (v) => !!v || 'Benchmark name is required',
      (v) =>
        (v !== undefined && v !== null && v.length > 2 && v.length < 33) ||
        'Benchmark name must be between 3 and 32 characters',
    ],
    abbrvRules: [
      (v) => !!v || 'Benchmark abbreviation is required',
      (v) =>
        (v !== null && v.length > 0 && v.length < 4) ||
        'Benchmark abbreviation must be between 1 and 3 characters',
    ],
    benchmarkNameLabel: '',
    benchmarkAbbrvLabel: '',
    globalAdmin: localStorage.getItem('globaladmin'),
    countryAdmin: localStorage.getItem('countryadmin'),
    adminCountry: localStorage.getItem('country'),
    customersPage: 2,
    customersListLoading: false,
    isCustomersLoadMoreAvailable: false,
    searchQuery: '',
    searchTimeout: null,
  }),
  computed: {
    filteredItems() {
      return this._.orderBy(
        this.searchOptions.customers.filter((item) => {
          if (!this.availableAccSearch && this.availableAccSearch === '') {
            return this.searchOptions.customers
          }
          return item.display
            ? item.display.toLowerCase().includes(this.availableAccSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
    selectedFilteredItems() {
      return this._.orderBy(
        this.selectedSearchFilters.customers.filter((item) => {
          if (!this.inputAccountSearch) {
            return this.selectedSearchFilters.customers
          }
          return item.display
            ? item.display.toLowerCase().includes(this.inputAccountSearch.toLowerCase())
            : null
        }),
        'headline'
      )
    },
    disableConfirm() {
      if (this.validateData() && !this.nameExists) {
        return true
      }
      return false
    },
  },
  methods: {
    async open() {
      this.$refs.loader.show()
      this.initialSetupOfData()
      await this.loadFilters()
      this.$refs.loader.hide()
      const result = await this.$refs.baseDialog.show()
      const benchmarkData = {
        benchmark_name: this.editBenchmarkName.trim(),
        legend_abbreviation: this.editBenchmarkAbbrv,
        customers: this.selectedSearchFilters.customers.filter((items) => {
          delete items.display
          delete items.is_customer_assigned_to_benchmark
          return items.value
        }),
        benchmark_country_code: this.globalAdmin ? this.benchmarkCountry : this.adminCountry,
        benchmark_type: this.globalAdmin ? 'global' : 'country',
      }
      const customersArray = []
      benchmarkData.customers.map((item) => {
        customersArray.push(item.value)
      })

      if (result === true) {
        if (this.validateData()) {
          return new Promise((resolve, reject) => {
            resolve(benchmarkData)
          })
        }
        this.$refs.baseDialog.show()
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
      this.$refs.loader.hide()
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
    editBenchmarkNameExistence(value) {
      let filteredBenchmarkArray = []
      if (value) {
        if (!this.isEditDialog) {
          filteredBenchmarkArray = this.listOfBenchmarks.filter(
            (benchmark) =>
              benchmark.benchmark_name.includes(value) && benchmark.benchmark_name == value
          )
        } else {
          if (value === this.editBenchmarkNameOriginal) {
            this.nameExists = false
            return false
          }
          filteredBenchmarkArray = this.listOfBenchmarks.filter(
            (benchmark) =>
              benchmark.benchmark_name.includes(value) && benchmark.benchmark_name === value
          )
        }

        if (filteredBenchmarkArray.length > 0) {
          this.nameExists = true
          return true
        }
        this.nameExists = false
        return false
      }
      this.nameExists = false
      return false
    },
    validateData() {
      if (
        !this.globalAdmin &&
        this.editBenchmarkName !== null &&
        this.editBenchmarkName !== '' &&
        this.selectedSearchFilters.customers.length > 0 &&
        this.editBenchmarkAbbrv !== null &&
        this.editBenchmarkAbbrv !== ''
      ) {
        return true
      }
      if (
        this.globalAdmin &&
        this.editBenchmarkName !== null &&
        this.editBenchmarkName !== '' &&
        this.selectedSearchFilters.customers.length > 0 &&
        this.editBenchmarkAbbrv !== null &&
        this.editBenchmarkAbbrv !== '' &&
        this.benchmarkCountry !== null
      ) {
        return true
      }
      return false
    },
    initialSetupOfData() {
      this.nameExists = false
      if (this.isEditDialog && this.benchmarkDetails) {
        this.editBenchmarkName = this.benchmarkDetails.benchmark_name
        this.editBenchmarkNameOriginal = this.benchmarkDetails.benchmark_name
        this.editBenchmarkAbbrv = this.benchmarkDetails.legend_abbreviation
      } else if (!this.isEditDialog) {
        this.benchmarkNameLabel = this.$t(
          'apps.trueVue_analytics.benchmark.create_benchmark.benchmark_name'
        )
        this.benchmarkAbbrvLabel = this.$t(
          'apps.trueVue_analytics.benchmark.create_benchmark.benchmark_abbreviation_input'
        )
        this.countryLabel = this.$t('apps.trueVue_analytics.benchmark.edit_benchmark.country')
        if (this.$refs.benchmarkNameInput) {
          this.$refs.benchmarkNameInput.resetValidation()
        }
        if (this.$refs.benchmarkAbbrvInput) {
          this.$refs.benchmarkAbbrvInput.resetValidation()
        }
        this.editBenchmarkName = null
        this.editBenchmarkAbbrv = null
        this.benchmarkCountry = null
      }
    },
    resetBenchmarkData() {
      const countryList = this.benchmarkCountries
      this.selectedSearchFilters.customers = []
      this.searchOptions.customers = []
      this.availableAccSearch = ''
      this.inputAccountSearch = ''

      if (!this.globalAdmin) {
        const countryAdminCountry = countryList.filter(
          (country) => country.id === this.adminCountry
        )
        this.benchmarkCountrySelect.country.display = countryAdminCountry[0].value
        this.benchmarkCountrySelect.country.value = countryAdminCountry[0].id
      } else if (!this.isEditDialog) {
        countryList.forEach((item) => {
          this.benchmarkCountrySelect.country.push({
            display: item.value,
            value: item.id,
          })
        })
      } else {
        const countryAdminCountry = countryList.filter(
          (country) => country.id === this.benchmarkDetails.country_of_creation
        )
        countryList.forEach((item) => {
          this.benchmarkCountrySelect.country.push({
            display: item.value,
            value: item.id,
          })
        })
        this.benchmarkCountry = countryAdminCountry[0].id
      }
    },
    async loadBenchmarkData() {
      try {
        const benchRequest = {
          params: {
            _limit: 3000,
            _page: 1,
            _orderBy: 'benchmark_name',
            _ascending: false,
            user_id: this.user_id,
          },
        }

        const tempDataFilters = {
          customers: JSON.stringify([]),
          country: JSON.stringify([]),
        }

        benchRequest.params = Object.assign(benchRequest.params, tempDataFilters)
        const benchmarksResponse = await this.getBenchmarks(benchRequest)
        this.listOfBenchmarks = benchmarksResponse.data
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async loadFilters() {
      try {
        this.searchQuery = ''
        this.customersPage = 2
        this.resetBenchmarkData()

        await this.loadBenchmarkData()
        const request = {
          params: {
            user_id: this.userId,
            country: JSON.stringify([]),
            users: JSON.stringify([]),
            user_role: JSON.stringify([]),
            _limit: this.limit,
            _orderBy: 'name',
            _page: 1,
            _ascending: true,
            is_flagged_for_benchmark: true,
          },
        }

        if (this.isEditDialog && this.benchmarkDetails) {
          if (this.benchmarkDetails.contributing_customers.length > 0) {
            this.benchmarkDetails.contributing_customers.forEach((element) => {
              this.selectedSearchFilters.customers.push({
                display: element.customername,
                value: element.customerkey,
                is_customer_assigned_to_benchmark: true,
                country_code: element.country,
              })
            })
          }
        }
        const response = await this.getCustomers(request)

        if (response.total_pages > 1) {
          this.isCustomersLoadMoreAvailable = true
        }
        response.data.forEach((items) => {
          const removedFromAssignedArray = this.selectedSearchFilters.customers.filter(
            (customer) => customer.value === items.id
          )
          if (removedFromAssignedArray.length > 0) {
            items.is_customer_assigned_to_benchmark = true
          } else {
            items.is_customer_assigned_to_benchmark = false
          }
          this.searchOptions.customers.push({
            display: items.name,
            value: items.id,
            is_customer_assigned_to_benchmark: items.is_customer_assigned_to_benchmark
              ? items.is_customer_assigned_to_benchmark
              : false,
            country_code: items.country_code,
          })
        })
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
    },
    async loadMoreCustomers() {
      try {
        this.customersListLoading = true
        const request = {
          params: {
            user_id: this.userId,
            country: JSON.stringify([]),
            users: JSON.stringify([]),
            user_role: JSON.stringify([]),
            _limit: this.limit,
            _orderBy: 'name',
            _page: this.customersPage,
            _ascending: true,
            search_term: this.searchQuery,
            is_flagged_for_benchmark: true,
          },
        }

        const response = await this.getCustomers(request)
        response.data.forEach((items) => {
          const removedFromAssignedArray = this.selectedSearchFilters.customers.filter(
            (customer) => customer.value === items.id
          )
          if (removedFromAssignedArray.length > 0) {
            items.is_customer_assigned_to_benchmark = true
          } else {
            items.is_customer_assigned_to_benchmark = false
          }
          this.searchOptions.customers.push({
            display: items.name,
            value: items.id,
            is_customer_assigned_to_benchmark: false,
            country_code: items.country_code,
          })
        })

        if (this.customersPage === response.total_pages) {
          this.isCustomersLoadMoreAvailable = false
        }

        this.customersPage += 1
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.customersListLoading = false
    },
    async searchForCustomersCheck() {
      const value = this.searchQuery
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.searchForCustomers(value)
      }, 500)
    },
    async searchForCustomers(value) {
      this.isCustomersLoadMoreAvailable = false
      this.customersPage = 2
      this.searchOptions.customers = []

      const request = {
        params: {
          user_id: this.userId,
          country: JSON.stringify([]),
          users: JSON.stringify([]),
          user_role: JSON.stringify([]),
          _limit: 3000,
          _orderBy: 'name',
          _page: 1,
          _ascending: true,
          search_term: value,
          is_flagged_for_benchmark: true,
        },
      }

      try {
        this.customersListLoading = true

        const response = await this.getCustomers(request)
        if (response) {
          console.log(response)
          if (this.searchQuery !== value) {
            console.log('here')
            return
          }
          if (response.total_pages > 1) {
            this.isCustomersLoadMoreAvailable = true
          }
          response.data.forEach((items) => {
            const removedFromAssignedArray = this.selectedSearchFilters.customers.filter(
              (customer) => customer.value === items.id
            )
            if (removedFromAssignedArray.length > 0) {
              items.is_customer_assigned_to_benchmark = true
            } else {
              items.is_customer_assigned_to_benchmark = false
            }

            if (items.name.toLowerCase().includes(value.toLowerCase())) {
              this.searchOptions.customers.push({
                display: items.name,
                value: items.id,
                is_customer_assigned_to_benchmark: items.is_customer_assigned_to_benchmark
                  ? items.is_customer_assigned_to_benchmark
                  : false,
                country_code: items.country_code,
              })
            } else if (value === null) {
              this.searchOptions.customers.push({
                display: items.name,
                value: items.id,
                is_customer_assigned_to_benchmark: items.is_customer_assigned_to_benchmark
                  ? items.is_customer_assigned_to_benchmark
                  : false,
                country_code: items.country_code,
              })
            }
          })

          if (this.customersPage === response.total_pages) {
            this.isCustomersLoadMoreAvailable = false
          }

          this.customersPage += 1
        }
      } catch (exception) {
        const exceptionDetail = decodeException(exception)
        this.$publishAlert(
          exceptionDetail.message.response.data.errorMessage,
          'error',
          exceptionDetail.detail_message
        )
      }
      this.customersListLoading = false
    },
    addCustomerToBenchmark(selectedItem) {
      const currentListOfCustomers = this.selectedSearchFilters.customers
      if (currentListOfCustomers.includes(selectedItem)) {
      } else {
        currentListOfCustomers.push({
          display: selectedItem.display,
          value: selectedItem.value,
          is_customer_assigned_to_benchmark: true,
          country_code: selectedItem.country_code,
        })
        this.searchOptions.customers.map((items) => {
          if (items.value === selectedItem.value) {
            items.is_customer_assigned_to_benchmark = true
          }
        })
        this.changesBeingMade = true
      }
    },
    removeCustomerFromBenchmark(selectedItem) {
      this.selectedSearchFilters.customers = this.selectedSearchFilters.customers.filter(
        (item) => item.value !== selectedItem.value
      )
      this.searchOptions.customers.map((items) => {
        if (items.value === selectedItem.value) {
          items.is_customer_assigned_to_benchmark = false
        }
      })
      this.changesBeingMade = true
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    .v-text-field__slot {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
  .v-text-field__details {
    margin-top: 5px;
  }
}

.search {
  max-width: 300px;
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}

::v-deep .v-card__title {
  padding-bottom: 0px !important;
  padding-left: 34px !important;
  padding-right: 34px !important;
}

v.divider {
  border: none;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 700px;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.customersLists {
  width: 403px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
  .v-list {
    padding-top: 0;
    .v-list-item {
      min-height: 43px;
      padding: 0 9px;
      .v-list-item__content {
        .v-list-item__title {
          color: #54585a;
          font-family: 'Noto Sans';
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

button.add {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 63px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

button.remove {
  min-height: 0px !important;
  max-height: 20px;
  min-width: 0px !important;
  max-width: 87px;
  background-color: #edeeee !important;
  font-size: 12px;
  font-family: 'Noto Sans', sans-serif;
  line-height: 16px;
  color: #54585a;
  font-weight: 100;
  .v-icon {
    height: 10px;
    width: 10px;
    color: #54585a;
  }
}

.notSaved {
  background-color: #eff0f0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 9px;
  line-height: 19px;
  padding-left: 4px;
  color: #54585a;
}

.message {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 12px;
  line-height: 19px;
}

.errorMessage {
  color: #ff5252;
  font-family: 'Noto Sans';
  font-size: 12px;
  line-height: 19px;
  padding-left: 6px;
  margin-bottom: -1px;
}

.header {
  padding: 0 12px;
}

.accountsHeader {
  padding: 12px 12px 0;
}

::v-deep .v-list-item {
  .v-list-item__content {
    .v-list-item__title {
      font-size: 14px;
      line-height: 19px;
      font-family: 'Noto Sans', sans-serif;
      color: #54585a;
    }
  }
}

.selectCountry {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.name {
  display: flex;
}

.noAssignedData {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}

.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: 110px;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}
</style>,

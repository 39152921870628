<template>
  <div>
    <WebDialog ref="baseDialog" width="770px" height="420px">
      <template v-slot:dialog-title>
        <span class="text-h6">
          {{ $t('apps.trueVue_analytics.benchmark.delete_benchmark.title') }}
        </span>
      </template>

      <template v-slot:dialog-content>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="6" lg="6" class="pa-0">
            <h4>
              {{ $t('apps.trueVue_analytics.benchmark.delete_benchmark.customers_assigned') }}
            </h4>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-auto">
          <v-col cols="12" sm="12" md="12" lg="12" class="px-0">
            <v-card class="customersLists mx-auto" elevation="0" outlined>
              <v-list>
                <v-list-item-group>
                  <template v-for="customer in selectedBenchmark.assigned_customers">
                    <v-list-item v-bind:key="customer.customerkey">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-text="customer.customername"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
                <span v-if="noCustomers" class="noAccountsMessage">
                  {{
                    $t('apps.trueVue_analytics.benchmark.delete_benchmark.no_customers_assigned')
                  }}
                </span>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:confirm-button-text>
        <span class="text-none">
          {{ $t('apps.trueVue_analytics.benchmark.delete_benchmark.delete_button') }}
        </span>
      </template>
    </WebDialog>

    <WebDataLoading ref="loader"></WebDataLoading>
  </div>
</template>,

<script>
import trueVueMgmtStoreHelper from '@plugins/trueVue-analytics/state/helpers'
import { decodeException } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'

export default {
  name: 'DeleteBenchmark',
  mixins: [trueVueMgmtStoreHelper],
  props: {
    benchmarkDetails: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    selectedBenchmark: [],
    noCustomers: false,
  }),
  methods: {
    async open() {
      this.noCustomers = false
      this.$refs.loader.show()
      this.selectedBenchmark = this.benchmarkDetails
      this.$refs.loader.hide()
      if (this.selectedBenchmark.assigned_customers.length < 1) {
        this.noCustomers = true
      }

      const result = await this.$refs.baseDialog.show()

      if (result === true) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      }
      this.$refs.loader.hide()
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    },
  },
}
</script>,

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  .v-input__control {
    .v-input__slot {
      margin-bottom: 0px !important;
    }
  }
}

.search {
  max-width: 300px;
}

span.title {
  color: #54585a;
  font-family: 'Noto Sans Bold', sans-serif !important;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 24px;
}
::v-deep .v-card__title {
  padding-bottom: 0px !important;
}

v.divider {
  border: none;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  width: 700px;
}

h4 {
  color: #001a72;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Noto Sans Bold', sans-serif;
}

.textAlignCenter {
  text-align: center;
}

::v-deep .v-label {
  font-size: 14px;
  line-height: 19px;
  font-family: 'Noto Sans', sans-serif;
  color: #54585a;
}

.customersLists {
  width: 729px;
  height: 210px;
  overflow-y: auto;
  border: 1px solid #d4d5d6 !important;
}

.messageSection {
  padding: 0 12px;
  .message {
    color: #54585a;
    font-family: 'Noto Sans';
    font-size: 12px;
    line-height: 19px;
  }
}

.v-list-item {
  min-height: 43px;
  padding: 0 9px;
  .v-list-item__content {
    .v-list-item__title {
      color: #54585a;
      font-family: 'Noto Sans';
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.noAccountsMessage {
  color: #54585a;
  font-family: 'Noto Sans';
  font-size: 14px;
  line-height: 19px;
  padding-left: 10px;
}
</style>,

<style type="text/css">
.v-card__actions .row {
  flex-direction: row-reverse;
  justify-content: flex-start !important;
}
.v-card__actions .row .col-md-4 {
  text-align: center;
}

.v-card__actions .row .col-md-4 button {
  width: auto;
  text-transform: capitalize;
}

.v-card__actions .row .col-lg-3 {
  text-align: right;
}
</style>,

<template>
  <div class="customerSize">
    <template v-for="customer in itemsToDisplay">
      <v-chip :key="customer.customerkey" class="customerChips">
        {{ customer.customername }}
      </v-chip>
    </template>
    <div :key="customer">
      <v-chip v-if="itemsHidden > 0" class="chipsExpander" @click="showAllItems"
        >+ {{ itemsHidden }}</v-chip
      >
      <v-chip
        v-if="itemsToDisplay.length > this.initialAllowedItems"
        class="chipsExpander"
        @click="hideItems"
        >- {{ this.countHiddenItems(items) }}</v-chip
      >
    </div>
  </div>
</template>
,

<script>
export default {
  name: 'AssociatedCustomers',
  props: ['items'],
  data: () => ({
    initialAllowedItems: 3,
    itemsToDisplay: [],
    itemsHidden: 0,
  }),
  mounted() {
    this.itemsToDisplay = this.prepareItemsToDisplay(this.items)
    this.itemsHidden = this.countHiddenItems(this.items)
  },
  watch: {
    items: {
      immediate: true,
      handler(val, oldVal) {
        this.itemsToDisplay = this.prepareItemsToDisplay(val)
        this.itemsHidden = this.countHiddenItems(val)
      },
    },
  },
  methods: {
    prepareItemsToDisplay(items) {
      return items ? items.slice(0, this.initialAllowedItems) : []
    },
    countHiddenItems(items) {
      return items && items.length > this.initialAllowedItems
        ? items.length - this.initialAllowedItems
        : 0
    },
    showAllItems() {
      this.itemsToDisplay = this.items
      this.itemsHidden = 0
    },
    hideItems() {
      this.itemsToDisplay = this.prepareItemsToDisplay(this.items)
      this.itemsHidden = this.countHiddenItems(this.items)
    },
  },
}
</script>
,

<style lang="scss" scoped>
.customerSize {
  padding: 0.5rem 0;
}

.customerChips {
  margin: 3px 2px 3px 0;
  border-radius: 30px;
  background-color: #edeeee;
  span {
    color: #54585a;
    font-family: 'Noto Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
}
.chipsExpander {
  display: inline-block;
  cursor: pointer;
  background-color: #003399 !important;
  color: #ffffff;
}

.v-chip.v-size--default {
  height: 22px;
  font-size: 12px;
}
</style>
,
